import React from 'react'

  import { Grid, Cell } from 'react-foundation';

  /**
   * Utilities
   */
  import '../../styles/navigation.css'

  /**
   * Components
   */

   import NavigationAvatar from './navigationAvatar'
   import NavigationItems from './navigationItems'
   import NavigationVersion from './navigationVersion'

export default class NavigationViewComponent extends React.Component{

    constructor(){
        super()

        this.state = {
            role:"",
            userName:"",
            idRole:0
        }

    }

    UNSAFE_componentWillMount(){
        var dataUser = JSON.parse(sessionStorage.dataUser)
        const self = this
        if(dataUser.id_role == 2){
            self.setState({role: "Abogado", userName:dataUser.name, idRole:2})
        }else if(dataUser.id_role == 1){
            self.setState({role: "Cliente", userName:dataUser.name, idRole:1})
        }else if(dataUser.id_role == 3){
            self.setState({role: "Gerente", userName:dataUser.name, idRole:3})
        }
    }

    render(){
        return(
            <Cell small={this.props.collapsed ? 1: 2} medium={this.props.collapsed ? 1: 2} large={this.props.collapsed ? 1: 2}>
                <nav className="cn-nav">
                    <button onClick={() => this.props.onToggleCollapse()}>{this.props.collapsed ? '>' : '<'}</button>
                    <NavigationAvatar 
                        role={this.state.role}
                        userName={this.state.userName}/>
                    <NavigationItems role={this.state.idRole} collapsed={this.props.collapsed}/>
                    <NavigationVersion/>
                </nav>
            </Cell>
        )
    }

}