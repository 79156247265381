import React from 'react'

import { Grid, Cell, Button } from 'react-foundation';
import "../../styles/record.css"


import RecordComponent from './recordComponent'
import BinnacleComponent from './binnacleComponent'

import Axios from 'axios'
import config from '../../config/config'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faFolder, faArrowAltCircleLeft} from '@fortawesome/free-solid-svg-icons'


export default class DetailRecordComponent extends React.Component{
    constructor(){
        super()

        this.state = {
            record : {},
            binnacles: []
        }

        this.getDataBinnacle = this.getDataBinnacle.bind(this)

    }

    UNSAFE_componentWillMount(){
        this.getDataBinnacle()
    }

    getDataBinnacle(){
        
        ///api/v1/files/:Id

        const self = this
        let record = this.props.idRecord
        var dataUser = JSON.parse(sessionStorage.dataUser)

        Axios.get(`${config.getIPAddress()}api/v1/files/${record}?idClient=${dataUser.id_user}`)
        .then(response => {
            if(response.status == 200){
                self.setState({record: response.data.res.information_file, binnacle: response.data.res.binnacle})
            }
        
        }).catch(err =>{
            //console.log(err)
        })
    }

    render(){
        return(
            <div style={{height:"100%", overflow:"hidden"}}>
                <Button style={{backgroundColor:"transparent", padding:'0px', margin:'0px', marginBottom:"10px"}} onClick={()=> this.props.onhandleSelectRecord(false)}>
                    <FontAwesomeIcon className="icon-arrow" icon={faArrowAltCircleLeft} size="3x" color={"#003A66"}/>
                </Button>

                <Grid style={{maxHeight:"100%"}}>
                    <Cell small={6} medium={6} large={6} style={{overflow:"auto",  marginTop:"10px"}}>
                        <RecordComponent record={this.state.record}/>
                    </Cell>
                    <Cell small={6} medium={6} large={6} style={{overflow:"auto"}}>
                        <BinnacleComponent binnacle={this.state.binnacle}/>
                    </Cell>
                </Grid>
            </div>
        )
    }
}