import React from 'react'

import { Grid, Cell, Button } from 'react-foundation';
import Modal from 'react-foundation-modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import Axios from 'axios';
import config from '../../config/config'
import Select from 'react-select'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const overlayStyle = {
    'backgroundColor': 'rgba(0,58,102,.45)'
    };

export default class OptionsUsersSystemMainComponent extends React.Component{
    constructor(){
        super()


        this.state = {
            modalIsOpen: false,
            internalId:"--",
            email:"",
            password:"",
            confirmPassword:"",
            userName:"",
            role:"",
            phone:"",
            cellphone:"",
            nickName:""
        }


        this.handleSubmitUser = this.handleSubmitUser.bind(this)
    }

    showPopup = (status) => {
        this.setState({
            modalIsOpen: status
        });
    }

    handleSubmitUser(event){
        event.preventDefault()

        const self = this

        if(self.state.password !== self.state.confirmPassword){
            toast.warn('Las contraseñas no coinciden.', {containerId: 'A'})
        }else{
            Axios.post(`${config.getIPAddress()}api/v1/users/`,{
                    internalId:self.state.internalId,
                    email:self.state.email,
                    password:self.state.password,
                    name:self.state.userName,
                    idRole:self.state.role,
                    phone:self.state.phone,
                    cellphone:self.state.cellphone,
                    nickName:self.state.nickName
            }).then(response => {

                if(response.status == 200){
                    toast.success("Usuario registrado correctamente.", {containerId: 'A'})
                    self.props.onhandleuserRegistered()
                    self.showPopup()
                }
        

            }).catch(err => {
                if(err.response.status == 404){
                    toast.error("Ocurrió un problema verifica los campos he intetalo de nuevo,", {containerId: 'A'})
                }

                if(err.response.status == 304){
                    toast.error("El correo electrónico o nombre de usuario ya fue utilizado.", {containerId: 'A'})
                }
            })
        }
    }

    render(){
        return(
            <Grid className="cn-options">
                <Cell className="cn-search" small={4} medium={4} large={4}>
                    <input placeholder="Buscar" type="text" onChange={(input) => {
                        this.props.onhandleSearch(input.target.value)
                    }}/>
                </Cell>

                <Cell style={{paddingLeft:10}} small={3} medium={3} large={3}>
                </Cell>

                <Cell style={{textAlign:"right"}} small={5} medium={5} large={5}>
                    <Button style={{backgroundColor:"#003A66"}} onClick={() => this.showPopup(true)}>
                        <FontAwesomeIcon icon={faPlus} size={"1x"} color={"#fff"}/>
                    </Button>
                    <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.TOP_RIGHT} />
                    <Modal 
                        open={this.state.modalIsOpen}
                        closeModal={this.showPopup}
                        isModal={true}
                        size="small"
                        overlayStyle={overlayStyle} >

                        <form onSubmit={(self) => this.handleSubmitUser(self)} style={{textAlign:"left"}}>
                            <h3>Nuevo Usuario</h3>
                            <Grid>
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Email*</label>
                                    <input type="text" onChange={(input)=>{
                                        this.setState({email:input.target.value})
                                    }}/>
                                </Cell>
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Usuario*</label>
                                    <input type="text" onChange={(input)=>{
                                        this.setState({nickName:input.target.value})
                                    }}/>
                                </Cell>
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Contraseña*<a href=""></a></label>
                                    <input type="password" onChange={(input)=>{
                                        this.setState({password:input.target.value})
                                    }}/>
                                </Cell>
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Confirmar contraseña*</label>
                                    <input type="password" onChange={(input)=>{
                                        this.setState({confirmPassword:input.target.value})
                                    }}/>
                                </Cell>
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Nombre*</label>
                                    <input type="text" onChange={(input)=>{
                                        this.setState({userName:input.target.value})
                                    }}/>
                                </Cell>
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Rol*</label>
                                    <select name="" id="" onChange={(input)=>{
                                        this.setState({role:input.target.value})
                                    }}>
                                        <option value="">
                                            -Seleccionar-
                                        </option>
                                        <option value="2">
                                            Abogado
                                        </option>
                                        <option value="3">
                                            Gerente
                                        </option>
                                    </select>
                                </Cell>
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Teléfono de oficina + ext</label>
                                    <input type="text" onChange={(input)=>{
                                        this.setState({phone:input.target.value})
                                    }}/>
                                </Cell>
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Teléfono celular</label>
                                    <input type="text" onChange={(input)=>{
                                        this.setState({cellphone:input.target.value})
                                    }}/>
                                </Cell>
                                <Cell className="cn-fr" small={10} medium={10} large={10}></Cell>
                                <Cell className="cn-fr" small={2} medium={2} large={2}>
                                    <Button>
                                        Guardar
                                    </Button>
                                </Cell>
                             
                            </Grid>


                        </form>

                        


                    </Modal>  
                </Cell>
            </Grid>
        )
    }
}