import React from 'react'

import { Grid, Cell } from 'react-foundation';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faUser, faFolder} from '@fortawesome/free-solid-svg-icons'


import ClientsMainComponent from './clientsMainComponent'
import CompaniesMainComponent from './companiesMainComponent'
import UsersSystemComponent from './usersSystemMainComponent'

import '../../styles/users.css'

export default class IndexUsersComponent extends React.Component{


    constructor(){
        super()


        this.state={
            tabsState:{
                first:true,
                second:false,
                three:false
            }
        }


        this.changeTabs = this.changeTabs.bind(this)
        this.renderContentTabs = this.renderContentTabs.bind(this)
    }

    changeTabs(state){

        this.setState({tabsState:state})

    }

    renderContentTabs(){
        const self = this
        if(self.state.tabsState.first){
            return(
                <ClientsMainComponent />
            )
        }else if(self.state.tabsState.second){
            return(
                <CompaniesMainComponent />
            )
        }else if(self.state.tabsState.three){
            return(
                <UsersSystemComponent />
            )
        }
    }

    render(){
        return(
            <Cell small={10} medium={10} large={10}>
                <div className="cn-main-titles">
                        <h4 className="text-color-main">
                        <FontAwesomeIcon style={{marginLeft:10}} className="icon-arrow" icon={faUser} size={"1x"} color={"#BFA568"}/>
                        <span style={{marginLeft:6}}>Usuarios</span></h4>  
                    </div>
                    <div className="cn-main-components">
                        <div className="cn-tabs">
                            <button onClick={() => this.changeTabs({first:true, second:false, three:false})} className={`btn-tabs ${this.state.tabsState.first ? "btn-active" : ""}`}>Clientes</button>
                            <button onClick={() => this.changeTabs({first:false, second:true, three:false})} className={`btn-tabs ${this.state.tabsState.second ? "btn-active" : ""}`}>Empresas</button>
                            <button onClick={() => this.changeTabs({first:false, second:false, three:true})} className={`btn-tabs ${this.state.tabsState.three ? "btn-active" : ""}`}>Usuarios</button>
                        </div>

                        <div className="cn-tabs-content">
                            {this.renderContentTabs()}
                        </div>  
                    </div>
            </Cell>
        )
    }

}