import React from 'react'

import { Grid, Cell, Button } from 'react-foundation';
import Modal from 'react-foundation-modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import Axios from 'axios'
import config from '../../config/config'

import Select from 'react-select'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const overlayStyle = {
    'backgroundColor': 'rgba(0,58,102,.45)'
    };

export default class OptionsComponents extends React.Component{
    constructor(){
        super()


        this.state = {
            modalIsOpen: false,
            internalId:"--",
            internal_file_number:"",
            idLawyer:0,
            external_file_number:"",
            rfc:"",
            city:"",
            court:"",
            register_date:"",
            issue:"",
            complain:"",
            id_client:0,
            rfcs:[],
            clients:[],
            issues:[],
            sending:false
            
        }


        this.drawOptions = this.drawOptions.bind(this)
        this.drawOptionsClients = this.drawOptionsClients.bind(this)
        this.setInternalId = this.setInternalId.bind(this)
        this.handleSubmitRecord = this.handleSubmitRecord.bind(this)
        this.getRFCs = this.getRFCs.bind(this)
        this.getClients = this.getClients.bind(this)
        this.drawNew = this.drawNew.bind(this)
        this.sendPriorities = this.sendPriorities.bind(this)
        this.getIssues = this.getIssues.bind(this)
        this.drawIssues = this.drawIssues.bind(this)
        this.setOptions = this.setOptions.bind(this)
    }

    showPopup = (status) => {
        this.setState({
            modalIsOpen: status
        });

        this.setInternalId()
    }

    UNSAFE_componentWillMount(){
        this.getRFCs()
        this.getIssues()
    }

    getRFCs(){
        var self = this

        Axios.get(`${config.getIPAddress()}api/v1/companies/rfc`,{
            total_rows:1000,
            current_page:1
        }).then(response => {
            if(response.status == 200){
                self.setState({rfcs: response.data.rfc})
            }
        }).catch(err =>{
            //console.log(err)
        })
    }

    getIssues(){
        ///api/v1/issues

        const self = this
        Axios.get(`${config.getIPAddress()}api/v1/issues`,{
            total_rows:1000,
            current_page:1
        }).then(response => {
            if(response.status == 200){
                self.setState({issues: response.data.data})
            }
        }).catch(err =>{
            //console.log(err)
        })

    }

    getClients(idCompany){
        const self = this
        Axios.get(`${config.getIPAddress()}api/v1/companies/client?idCompany=${idCompany}`,{
            total_rows:1000,
            current_page:1
        }).then(response => {
            if(response.status == 200){
                self.setState({clients: response.data.clients})
            }
        }).catch(err =>{
            //console.log(err)
        })
    }

    drawIssues(){
        if(this.state.issues.length > 0){
           
            let rfcs = this.state.issues.map(issue=>{
                return(
                    <option value={issue.idIssue}>
                        {issue.issue}
                    </option>
                )
            })

            return rfcs

        }else{
            return false
        }
    }

    setOptions(){
        if(this.state.rfcs.length > 0){
            let rfcs = this.state.rfcs.map(rfc=>{
                return(
                    {
                        value:rfc.rfc,
                        label:rfc.rfc + " - " + rfc.companyName
                    }
                )
            })

            return rfcs

        }else{
            return false
        }
    }

    drawRFC(){
        if(this.state.rfcs.length > 0){
            let rfcs = this.state.rfcs.map(rfc=>{
                return(
                    <option value={rfc.rfc}>
                        {rfc.rfc + " - " + rfc.companyName}
                    </option>
                )
            })

            return rfcs

        }else{
            return false
        }
    }

    sendPriorities(){
        const self = this

    
            Axios.get(`${config.getIPAddress()}api/v1/files/priority`).then(response => {
                if(response.status == 200){
                    toast.success("Prioritarios enviados correctamente.", {containerId: 'A'})
                }

                if(response.status == 404){
                    toast.error("Ocurrió un problema verifica los campos he intetalo de nuevo,", {containerId: 'A'})
                }

            }).catch(err => {
                if(err){
                    toast.error("Ocurrió un problema, si el problema persiste contacta al administrador,", {containerId: 'A'})
                } 
            })
    }

    setInternalId(){

        const self = this
        var month = new Date().getUTCMonth() + 1
        month = month < 10 ? "0"+month.toString() : month.toString()
        var year = new Date().getUTCFullYear().toString()

        

        Axios.get(`${config.getIPAddress()}api/v1/files/month`,{
            total_rows:1000,
            current_page:1
        }).then(response => {
            if(response.status == 200){
                self.setState({internal_file_number:`${month}-${year}-${response.data.files}`})  
                //self.setState({clients: response.data.data})
            }
        }).catch(err =>{
            //console.log(err)
        })
    }

    handleSubmitRecord(event){
        event.preventDefault()

        const self = this

 
            self.setState({sending:true})
            Axios.post(`${config.getIPAddress()}api/v1/files`,{
                    internalId:self.state.internalId,
                    internal_file_number:self.state.internal_file_number,
                    idLawyer:self.state.idLawyer,
                    external_file_number:self.state.external_file_number,
                    rfc:self.state.rfc,
                    city:self.state.city,
                    court:self.state.court,
                    register_date:self.state.register_date,
                    issue:self.state.issue,
                    id_client:self.state.id_client,
                    complain:self.state.complain
            }).then(response => {
                self.setState({sending:false})
                if(response.status == 200){
                    toast.success("Expediente registrado correctamente.", {containerId: 'A'})
                    self.props.onhandlerecordRegistered()
                    self.showPopup()
                }

                

            }).catch(err => {
                self.setState({sending:false})
                if(err.response.status == 404){
                    toast.error("Ocurrió un problema verifica los campos he intetalo de nuevo,", {containerId: 'A'})
                }else{
                        toast.error("Ocurrió un problema, si el problema persiste contacta al administrador.", {containerId: 'A'})
                }
            })
        
    }


    drawOptions(){

        if(this.props.lawyers.length > 0){
            var lawyer = this.props.lawyers.map(lawyer => {
                return(
                    <option value={lawyer.id_user}>
                       {lawyer.name} 
                    </option>
                )
            })
            return lawyer
        }else{
            return false
        }

    }

    drawOptionsClients(){

        if(this.state.clients.length > 0){
            var client = this.state.clients.map(client => {
                return(
                    <option value={client.idUser}>
                       {client.userName} 
                    </option>
                )
            })
            return client
        }else{
            return false
        }

    }

    drawNew(){
        var dataUser = JSON.parse(sessionStorage.dataUser)

        if(dataUser.id_role == 3){
            return(<Button style={{backgroundColor:"#003A66"}} onClick={() => this.showPopup(true)}>
                    <FontAwesomeIcon icon={faPlus} size={"1x"} color={"#fff"}/>
                    </Button>)
        }else{
            return false
        }

    }

    
    render(){
        console.log('stageFilter Options',this.props.stageFilter);
        
        this.drawNew()
        return(
            <Grid className="cn-options">
                <Cell className="cn-search" small={4} medium={4} large={4}>
                    <input placeholder="Buscar" type="text" onChange={(input) => {
                        this.props.onhandleSearch(input.target.value)
                    }}/>
                </Cell>

                <Cell style={{paddingLeft:10}} small={3} medium={3} large={3}>
                    <select name="" id="" onChange={(input) => {
                        this.props.onhandleFilter(input.target.value)
                    }}>
                        <option value="numberInternal">Por número interno</option>
                        <option value="numberExternal">Por número externo</option>
                        <option value="company">Por nombre de empresa</option>
                        <option value="quejosos">Por quejosos</option>
                        <option value="issue">Por Asunto</option>
                    </select>
                    
                </Cell>

                <Cell style={{paddingLeft:10}} small={2} medium={2} large={2}>
                    <select name="" id="" placeholder='Elige etapa' onChange={(input)=>{
                        //console.log(input.target.value)
                        this.props.onhandleStageFilter(input.target.value)
                    }}>
                        <option value="0" selected={this.props.stageFilter == 0}>Todas las etapas</option>
                        <option value="1" selected={this.props.stageFilter == 1}>Concluido</option>
                        <option value="2" selected={this.props.stageFilter == 2}>En trámite</option>
                    </select>
                </Cell>

                <Cell style={{textAlign:"right"}} small={2} medium={2} large={2}>
                    <Button className="alert" onClick={()=> this.sendPriorities()}>Enviar prioritarios</Button>
                </Cell>


                <Cell style={{textAlign:"right", "width": "auto","padding-left": "10px"}} small={3} medium={3} large={3}>
                    {this.drawNew()}
                    <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.TOP_RIGHT} />
                    <Modal 
                        open={this.state.modalIsOpen}
                        closeModal={this.showPopup}
                        isModal={true}
                        size="small"
                        overlayStyle={overlayStyle} >

                        <form onSubmit={(self) => this.handleSubmitRecord(self)} style={{textAlign:"left"}}>
                            <h3>Nuevo expediente</h3>
                            <Grid>
                        
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Asignar abogado*</label>
                                    <select onChange={(input)=>{
                                        this.setState({idLawyer:input.target.value})
                                    }}>
                                        <option value="">--Seleccionar Abogado--</option>
                                        {this.drawOptions()}
                                    </select>
                                </Cell>
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>No. de expediente interno*</label>
                                    <input defaultValue={this.state.internal_file_number} readOnly type="text"/>
                                </Cell>
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>No. de expediente externo</label>
                                    <input type="text" onChange={(input)=>{
                                        this.setState({external_file_number:input.target.value})
                                    }}/>
                                </Cell>
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>RFC Empresa*</label>
                                    <Select options={this.setOptions()}
                                    onChange={(input)=>{
                                        if(input != null){
                                            this.setState({rfc:input.value})
                                            this.getClients(input.value)
                                            
                                        }
                                    }} 
                                    isClearable={true}/>
                                    
                                </Cell>
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Ciudad(Juzgado)</label>
                                    <input type="text" onChange={(input)=>{
                                        this.setState({city:input.target.value})
                                    }}/>
                                </Cell>
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Juzgado</label>
                                    <input type="text" onChange={(input)=>{
                                        this.setState({court:input.target.value})
                                    }}/>
                                </Cell>
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Fecha de alta*</label>
                                    <input type="date" onChange={(input)=>{
                                        this.setState({register_date:input.target.value})
                                    }}/>
                                </Cell>

                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Asunto*</label>
                                    <select name="" id="" onChange={(input)=>{
                                        this.setState({issue:input.target.value})
                                    }}>
                                        <option value="">-Seleccionar asunto-</option>
                                        {this.drawIssues()}
                                    </select>
                                </Cell>
                                <Cell className="cn-fr" small={12} medium={12} large={12}>
                                    <label>Quejoso(s)*</label>
                                    <textarea rows="3" onChange={(input)=>{
                                        this.setState({complain:input.target.value})
                                    }}></textarea>
                                </Cell>
                                <Cell className="cn-fr" small={10} medium={10} large={10}></Cell>
                                <Cell className="cn-fr" small={2} medium={2} large={2}>
                                    <Button isDisabled={this.state.sending}>
                                        Guardar
                                    </Button>
                                </Cell>
                             
                            </Grid>


                        </form>

                        


                    </Modal>  
                </Cell>
            </Grid>
        )
    }
}