import React from 'react'

import { Grid, Cell, Button } from 'react-foundation';
import Modal from 'react-foundation-modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import Axios from 'axios';
import config from '../../config/config'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const overlayStyle = {
    'backgroundColor': 'rgba(0,58,102,.45)'
    };

export default class OptionsCompaniesComponent extends React.Component{
    constructor(){
        super()


        this.state = {
            modalIsOpen: false,
                internalId:"--",
                company_name:"",
                rfc:"",
                businessName:"",
                address:"",
                phone:"",
                legalProxies:"",
                internalContact1:"",
                internalContact2:"",
                internalContact3:"",
                celContact1:"",
                celContact2:"",
                celContact3:""
        }

        this.handleSubmitCompanie = this.handleSubmitCompanie.bind(this)

    }

    showPopup = (status) => {
        this.setState({
            modalIsOpen: status
        });
    }

    handleSubmitCompanie(event){
        event.preventDefault()

        const self = this

        Axios.post(`${config.getIPAddress()}api/v1/users/companies`,{
                internalId:self.state.internalId,
                company_name:self.state.company_name,
                rfc:self.state.rfc,
                businessName:self.state.businessName,
                address:self.state.address,
                phone:self.state.phone,
                legalProxies:self.state.legalProxies,
                internalContact1:self.state.internalContact1,
                internalContact2:self.state.internalContact2,
                internalContact3:self.state.internalContact3,
                cellContact1:self.state.celContact1,
                cellContact2:self.state.celContact2,
                cellContact3:self.state.celContact3
        }).then(response => {
            if(response.status == 200){
                toast.success("Empresa registrada correctamente.", {containerId: 'A'})
                self.props.onhandleCompanyRegistered()
                self.showPopup()
            }

            if(response.status == 404){
                toast.error("Ocurrió un problema verifica los campos he intetalo de nuevo,", {containerId: 'A'})
            }

        }).catch(err => {
            toast.error("Ocurrió un problema, si el problema persiste contacta al administrador,", {containerId: 'A'})
        })

    }


    render(){
        return(
            <Grid className="cn-options">
                <Cell className="cn-search" small={4} medium={4} large={4}>
                    <input placeholder="Buscar" type="text" onChange={(input) => {
                        this.props.onhandleSearch(input.target.value)
                    }}/>
                </Cell>

                <Cell style={{paddingLeft:10}} small={3} medium={3} large={3}>
                </Cell>

                <Cell style={{textAlign:"right"}} small={5} medium={5} large={5}>
                    <Button style={{backgroundColor:"#003A66"}} onClick={() => this.showPopup(true)}>
                    <FontAwesomeIcon icon={faPlus} size={"1x"} color={"#fff"}/>
                    </Button>
                    <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.TOP_RIGHT} />
                    <Modal 
                        open={this.state.modalIsOpen}
                        closeModal={this.showPopup}
                        isModal={true}
                        size="small"
                        overlayStyle={overlayStyle} >

                        <form onSubmit={(self) => this.handleSubmitCompanie(self)} style={{textAlign:"left"}}>
                            <h3>Nueva empresa</h3>
                            <Grid>
                   
                            <Cell className="cn-fr" small={12} medium={12} large={12}>
                                    <label>Nombre de la empresa*</label>
                                    <input type="text" onChange={(input)=>{
                                        this.setState({company_name:input.target.value})
                                    }}/>
                                </Cell>
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>RFC*</label>
                                    <input type="text" onChange={(input)=>{
                                        this.setState({rfc:input.target.value})
                                    }}/>
                                </Cell>
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Razón social*<a href=""></a></label>
                                    <input type="text" onChange={(input)=>{
                                        this.setState({businessName:input.target.value})
                                    }}/>
                                </Cell>
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Domicilio*</label>
                                    <input type="text" onChange={(input)=>{
                                        this.setState({address:input.target.value})
                                    }}/>
                                </Cell>
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Teléfonos*</label>
                                    <input type="text" onChange={(input)=>{
                                        this.setState({phone:input.target.value})
                                    }}/>
                                </Cell>
                                <Cell className="cn-fr" small={12} medium={12} large={12}>
                                    <label>Apoderados legales*</label>
                                    <input type="text" onChange={(input)=>{
                                        this.setState({legalProxies:input.target.value})
                                    }}/>
                                </Cell>
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Contacto interno 1</label>
                                    <input type="text" onChange={(input)=>{
                                        this.setState({internalContact1:input.target.value})
                                    }}/>
                                </Cell>
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Teléfono celular 1</label>
                                    <input type="text" onChange={(input)=>{
                                        this.setState({celContact1:input.target.value})
                                    }}/>
                                </Cell>
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Contacto interno 2</label>
                                    <input type="text" onChange={(input)=>{
                                        this.setState({internalContact2:input.target.value})
                                    }}/>
                                </Cell>
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Teléfono celular 2</label>
                                    <input type="text" onChange={(input)=>{
                                        this.setState({celContact2:input.target.value})
                                    }}/>
                                </Cell>
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Contacto interno 3</label>
                                    <input type="text" onChange={(input)=>{
                                        this.setState({internalContact3:input.target.value})
                                    }}/>
                                </Cell>
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Teléfono celular 3</label>
                                    <input type="text" onChange={(input)=>{
                                        this.setState({celContact3:input.target.value})
                                    }}/>
                                </Cell>
                                <Cell className="cn-fr" small={10} medium={10} large={10}></Cell>
                                <Cell className="cn-fr" small={2} medium={2} large={2}>
                                    <Button >
                                        Guardar
                                    </Button>
                                </Cell>
                            </Grid>

                            </form>
                    </Modal>  
                </Cell>
            </Grid>
        )
    }
}