import React from 'react'

import { Grid, Cell } from 'react-foundation';
// <MainTargetsComponent onhandleSelectRecord={this.handleSelectRecord}/>

import "../../styles/record.css"

import OptionsComponent from './optionsComponents'
import DetailRecordComponent from './detailRecordComponent'
import MainTableComponent from './mainTableComponent'

import Axios from 'axios'
import config from '../../config/config'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faUser, faFolder} from '@fortawesome/free-solid-svg-icons'

export default class IndexRecordComponent extends React.Component{

    constructor(){
        super()
        this.state = {
            selectedRecord:false,
            idRecord:0,
            lawyers:[],
            clients:[],
            forceUpdate:false,
            search:"",
            filter:["numberInternal"],
            stageFilter: 2
        }

        this.handleSelectRecord = this.handleSelectRecord.bind(this)
        this.drawComponents = this.drawComponents.bind(this)
        this.getLawyers = this.getLawyers.bind(this)
        this.getClients = this.getClients.bind(this)
        this.handlerecordRegistered = this.handlerecordRegistered.bind(this)
        this.handleSearch = this.handleSearch.bind(this)
        this.handleFilter = this.handleFilter.bind(this)
        this.handleStageFilter = this.handleStageFilter.bind(this)

    }

    handleSelectRecord(state, idRecord){
        this.setState({selectedRecord:state, idRecord})
    }

    handleSearch(textToSearch){
        this.setState({search:textToSearch})
    }

    handleFilter(filter){
        this.setState({filter:[filter]})
    }

    handleStageFilter(filter){
        //console.log(filter)
        this.setState({stageFilter: filter})
    }

    handlerecordRegistered(){

        const self = this
        self.setState({forceUpdate:true})

    }

    UNSAFE_componentWillMount(){
        this.getLawyers()
        this.getClients()
    }



    getLawyers(){
        
            var self = this
    
            Axios.get(`${config.getIPAddress()}api/v1/users/lawyers/all`,{
                total_rows:1000,
                current_page:1
            }).then(response => {
                if(response.status == 200){
                    self.setState({lawyers: response.data.data})
                }
            }).catch(err =>{
                //console.log(err)
            })
        
    }

    getClients(){
        
        var self = this

        Axios.get(`${config.getIPAddress()}api/v1/users/clients/all`,{
            total_rows:1000,
            current_page:1
        }).then(response => {
            if(response.status == 200){
                self.setState({clients: response.data.data})
            }
        }).catch(err =>{
            //console.log(err)
        })
    
}

    drawComponents(){
        if(!this.state.selectedRecord){
            return(
                <div>
                    <label className="txt-instructions">Puedes buscar un expediente incluyendo algún filtro para facilitar la búsqueda. </label>
                    <OptionsComponent  
                        lawyers={this.state.lawyers} 
                        clients={this.state.clients}
                        onhandlerecordRegistered={this.handlerecordRegistered}
                        onhandleSearch={this.handleSearch}
                        onhandleFilter={this.handleFilter}
                        onhandleStageFilter={this.handleStageFilter}
                        stageFilter={this.state.stageFilter}
                    />
                    <MainTableComponent 
                        onhandleSelectRecord={this.handleSelectRecord}
                        textToSearch={this.state.search}
                        filter={this.state.filter}
                        stageFilter={this.state.stageFilter}
                        onhandlerecordRegistered={this.handlerecordRegistered}
                        lawyers={this.state.lawyers} 
                        clients={this.state.clients}
                    />
                </div>
            )
        }else{
            return <DetailRecordComponent idRecord={this.state.idRecord} onhandleSelectRecord={this.handleSelectRecord}/>
        }
    }


    render(){
            return(
                <Cell small={this.props.expanded ? 11 : 10} medium={this.props.expanded ? 11 : 10} large={this.props.expanded ? 11 : 10} style={{overflow:"hidden", maxHeight:"94vh"}}>
                    <div className="cn-main-titles">
                        <h4 className="text-color-main">
                        <FontAwesomeIcon style={{marginLeft:10}} className="icon-arrow" icon={faFolder} size={"1x"} color={"#BFA568"}/>
                        <span style={{marginLeft:6}}>Expedientes</span></h4>  
                    </div>
                    <div className="cn-main-components cn-record">
                        {this.drawComponents()}
                    </div>
                </Cell>
            )
        
        
    }

}