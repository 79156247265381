exports.getIPAddress = function(){

    let serverLaEnyelHome = "http://192.168.0.10:2406/"
    let serverDeivi = "http://192.168.0.12:2222/"
    //let serverOrduxProduct = "https://ordux-back.ralex.dev/"
    let serverOrduxProduct = "https://legal.ordux.mx:8443/"
    let serverQA = "http://54.209.130.150:1300/"


    return serverOrduxProduct

}

exports.containsCompanies = function(companies, obj){

    let flag = false
    companies.map(company => {
        if(company.idCompany == obj.idCompany){
            flag = true
        }
    })

    return flag
}
