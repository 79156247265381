import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faUser} from '@fortawesome/free-solid-svg-icons'

export default class PostBinnacleComponent extends React.Component{

    constructor(){
        super()

    }

    render(){
        return(
            <div className="cn-binnacle-card">
                <div className="cn-binnacle-card-title">
                    <a onClick={()=>this.props.onHandleAppear(this.props.position)}><span className="txt-binnacle-card-title">{this.props.data.isChange == 0 ? "Sin cambio" : this.props.data.title}</span></a>
                </div>

                <div className="cn-binnacle-card-observations">
                    <p className="txt-binnable-card-observations">{this.props.data.statusClient}</p>
                </div>

                <div className="cn-binnacle-card-footer">
                    <div className="cn-binnacle-card-editedby">
                    <FontAwesomeIcon icon={faUser} size="1x" color={"#003A66"}/>
                        <span className="txt-binnacle-card-editedby">
                            {this.props.data.lawyerName}
                        </span>
                    </div>
                    <div className="cn-binnacle-card-dateAudency">
                        <span className="txt-binnacle-card-dateAudency">
                            {this.props.data.createdAt}
                        </span>
                    </div>  
                </div>
                
                
            </div>
        )
    }



}

