import React from 'react'

import OptionsClientsComponent from './optionsClientsComponent'
import MainTableClientComponent from './mainTableClientComponent'

import Loader from 'react-loader-spinner'

import Axios from 'axios'
import config from '../../config/config'

export default class ClientsMainComponet extends React.Component{
    constructor(){
        super()

        this.state = {
            companies : [],
            forceUpdate:false,
            search:""
        }

        this.getCompanies = this.getCompanies.bind(this)
        this.handleclientRegistered = this.handleclientRegistered.bind(this)
        this.handleSearch = this.handleSearch.bind(this)

    }

    UNSAFE_componentWillMount(){

        this.getCompanies()
    }

    UNSAFE_componentWillReceiveProps(){
        this.getCompanies()
    }

    handleclientRegistered(){

        const self = this

        self.setState({forceUpdate:true})

    }

    handleSearch(textToSearch){
        this.setState({search:textToSearch})
    }


    getCompanies(){
        var self = this

        Axios.get(`${config.getIPAddress()}api/v1/users/companies/all`,{
            total_rows:1000,
            current_page:1
        }).then(response => {
            if(response.status == 200){
                self.setState({companies: response.data.res})
            }
        }).catch(err =>{
            //console.log(err)
        })
    }

    render(){
        return(
            <div>
                <OptionsClientsComponent 
                    companies={this.state.companies} 
                    onhandleclientRegistered={this.handleclientRegistered}
                    onhandleSearch={this.handleSearch}/>
                <MainTableClientComponent 
                    onhandleclientRegistered={this.handleclientRegistered}
                    textToSearch={this.state.search}
                    companies={this.state.companies}/>
                    
            </div>
        )
    }
}