// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../images/bglogin.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".bg-image{\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-size: contain;\n    background-repeat: round;\n    height: 100%;\n    width: 100%;\n    z-index: -2;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.filter{\n    height: 100%;\n    width: 100%;\n    background-color: #BFA568;\n    position: absolute;\n    opacity: .3;\n}\n\n.center{\n    margin: auto;\n}\n\n.cn-main{\n    width: 90%;\n    z-index: 1000;\n    height: 80%;\n}\n\n.cn-first{\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n    height: 100%;\n    padding: 50px;\n    background-color: #ffffff;\n}\n\n.cn-logo{\n    width: 100%;\n    text-align: center;\n    margin-bottom: 20px;\n}\n\n.cn-slogan{\n    width: 100%;\n    text-align: center;\n}\n\n.cn-second{\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n   border-left: 1px solid #DDDDDD;\n   background-color: #ffffff;\n}\n\n.cn-form{\n    width: 70%;\n}\n\n.cn-session{\n    margin-bottom: 25px;\n}\n\n.txt-label{\n    font-size: 12px;\n    padding: 10px;\n    padding-left: 0;\n}\n", ""]);
// Exports
module.exports = exports;
