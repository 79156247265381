import React from 'react'

import '../../styles/navigation.css'
import user from '../../images/user.png';

export default class NavigationAvatar extends React.Component{
    constructor(){
        super()

        

    }

    

    render(){
        return(
            <div className="cn-avatar">
                <img src={user} width={110} height={110}/>
                <div>
                <label className="txt-username text-color-main">{this.props.userName}</label>
                    <label className="txt-rol text-color-three">{this.props.role}</label>
                </div>
                
            </div>
        )
    }
}