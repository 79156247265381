import React from 'react'
import { Grid, Cell, InputTypes, Button } from 'react-foundation'

import '../../styles/record.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faListAlt, faEye, faEdit} from '@fortawesome/free-solid-svg-icons'

import Loader from 'react-loader-spinner'

import Modal from 'react-foundation-modal';

import Axios from 'axios'
import config from '../../config/config'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const overlayStyle = {
    'backgroundColor': 'rgba(0,58,102,.45)'
    };

export default class MainTableCompaniesComponent extends React.Component{

    constructor(){
        super()


        this.state = {
            idRecord:0,
            companies:[],
            modalIsOpen: false,
            textToSearch:"",
            company:{},
            title:"",
            isEditing:false,
            idCompany:0,
            businessName:"",
            address:"",
            rfc:"",
            company_name:"",
            phone:"",
            legalProxies:"",
            internalContact1:"",
            internalContact2:"",
            internalContact3:"",
            cellContact1:"",
            cellContact2:"",
            cellContact3:""

        }


        this.drawRows = this.drawRows.bind(this)
        this.updateRecordSelected = this.updateRecordSelected.bind(this)
        this.selectRow = this.selectRow.bind(this)
        this.getCompanies = this.getCompanies.bind(this)
        this.getCompany = this.getCompany.bind(this)
        this.buttonUpdate = this.buttonUpdate.bind(this)
        this.handleSubmitUser = this.handleSubmitUser.bind(this)

    }

    UNSAFE_componentWillMount(){

        this.getCompanies()
        
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        this.getCompanies(nextProps.textToSearch)
    }

    handleSubmitUser(event){
        event.preventDefault()

        const self = this

        Axios.put(`${config.getIPAddress()}api/v1/users/companies`,{
            idCompany:self.state.idCompany,
            company_name:self.state.company_name,
            businessName:self.state.businessName,
            address:self.state.address,
            rfc: self.state.rfc,
            phone:self.state.phone,
            legalProxies:self.state.legalProxies,
            internalContact1:self.state.internalContact1,
            internalContact2:self.state.internalContact2,
            internalContact3:self.state.internalContact3,
            cellContact1:self.state.cellContact1,
            cellContact2:self.state.cellContact2,
            cellContact3:self.state.cellContact3
    }).then(response => {
        if(response.status == 200){
            toast.success("Empresa actualizada correctamente.", {containerId: 'A'})
            self.props.onhandleCompanyRegistered()
            self.showPopup()
        }
        else if(response.status == 404){
            toast.error("Ocurrió un problema verifica los campos he intetalo de nuevo,", {containerId: 'A'})
        }

    }).catch(err => {
        //console.log(err)
        toast.error("Ocurrió un problema, si el problema persiste contacta al administrador,", {containerId: 'A'})
    })
    }

    getCompanies(textToSearch){
        var self = this

        Axios.get(`${config.getIPAddress()}api/v1/users/companies?total_rows=${1000}&current_page=${1}&company_name=${textToSearch != undefined ? textToSearch : ""}`,{
            total_rows:1000,
            current_page:1,
            company_name:this.props.textToSearch
        }).then(response => {

            if(response.status == 200){
                self.setState({companies: response.data.data})
            }
        
        }).catch(err =>{
            //console.log(err)
        })
    }

    getCompany(idCompany){
        const self = this

        self.setState({company:{}, idCompany:0})

        Axios.get(`${config.getIPAddress()}api/v1/users/companies/${idCompany}`,{
            total_rows:1000,
            current_page:1
        }).then(response => {

            if(response.status == 200){
                self.setState({company: response.data.res[0], idCompany:idCompany}, this.showPopup(true))
            }
        
        }).catch(err =>{
            //console.log(err)
        })
    }

    showPopup = (status) => {
        this.setState({
            modalIsOpen: status
        });
    }

    updateRecordSelected(idRecord){
        this.setState({idRecord:idRecord}, this.showPopup(true))
    }

    selectRow(idRecord){
        this.props.onhandleSelectRecord(true, idRecord)
    }

    //1 actualización
    //0 sin actualización
    //2 sin cambio

    drawRows(){


        if(this.state.companies.length > 0){

            var rows = this.state.companies.map( data => {
                var status = ""

                return(
                    <tr>
                        <td>
                            <span>{data.companyName}</span>
                        </td>
                        <td>
                            <span>{data.rfc}</span>
                        </td>
                        <td>
                            <a style={{marginLeft:"5px"}} href="#" onClick={() => { 
                                this.setState({isEditing:false, title:"Visualizar Empresa"})
                               this.getCompany(data.idcompany)
                            }}><FontAwesomeIcon icon={faEye} size="1x"/></a>
                            <a style={{marginLeft:"5px"}} href="#" onClick={() => { 
                                this.setState({isEditing:true, title:"Actualizar Empresa"})
                                this.getCompany(data.idcompany)
                            }}><FontAwesomeIcon icon={faEdit} size="1x"/></a>
                        </td>
                    </tr>
                )
            })

            return rows

        }else{
            return(
                <tr>
                    <td colSpan={4} style={{textAlign:"center"}}>
                        <b>No hay datos que mostrar.</b>
                        <Loader
                            type="Puff"
                            color="#003A66"
                            height={100}
                            width={100}
                            timeout={10000} //3 secs
                        />
                    </td>
                </tr>
            )
        }

    }

    buttonUpdate(){
        
        return this.state.isEditing ? <Button>Actualizar</Button> : false
    }

    render(){
        return(
            <Grid>
                <Cell small={12} medium={12} large={12}>
                    <table className="hover unstriped">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>RFC Empresa</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.drawRows()}
                        </tbody>
                    </table>

                    <Modal 
                        open={this.state.modalIsOpen}
                        closeModal={this.showPopup}
                        isModal={true}
                        size="small"
                        overlayStyle={overlayStyle} >

                        <form onSubmit={(self) => this.handleSubmitUser(self)} style={{textAlign:"left"}}>
                            <h3>{this.state.title}</h3>
                            <Grid>
                   
                            <Cell className="cn-fr" small={12} medium={12} large={12}>
                                    <label>Nombre de la empresa</label>
                                    <input type="text" 
                                    readOnly={!this.state.isEditing ? true : false} 
                                    defaultValue={this.state.company.companyName}
                                    onChange={(input)=>{
                                        this.setState({company_name:input.target.value})
                                    }}/>
                                </Cell>
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>RFC</label>
                                    <input type="text" 
                                    readOnly={!this.state.isEditing ? true : false}
                                    defaultValue={this.state.company.rfc}
                                    onChange={(input)=>{
                                        this.setState({rfc:input.target.value})
                                    }}/>
                                </Cell>
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Razón social<a href=""></a></label>
                                    <input type="text" 
                                    readOnly={!this.state.isEditing ? true : false}
                                    defaultValue={this.state.company.businessName}
                                    onChange={(input)=>{
                                        this.setState({businessName:input.target.value})
                                    }}/>
                                </Cell>
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Domicilio</label>
                                    <input type="text" 
                                    readOnly={!this.state.isEditing ? true : false}
                                    defaultValue={this.state.company.address}
                                    onChange={(input)=>{
                                        this.setState({address:input.target.value})
                                    }}/>
                                </Cell>
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Teléfono</label>
                                    <input type="text" 
                                    readOnly={!this.state.isEditing ? true : false}
                                    defaultValue={this.state.company.phone}
                                    onChange={(input)=>{
                                        this.setState({phone:input.target.value})
                                    }}/>
                                </Cell>
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Apoderados legales</label>
                                    <input type="text" 
                                    readOnly={!this.state.isEditing ? true : false}
                                    defaultValue={this.state.company.legalProxies}
                                    onChange={(input)=>{
                                        this.setState({legalProxies:input.target.value})
                                    }}/>
                                </Cell>
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Contacto interno 1</label>
                                    <input type="text" 
                                    readOnly={!this.state.isEditing ? true : false}
                                    defaultValue={this.state.company.internalContact1}
                                    onChange={(input)=>{
                                        this.setState({internalContact1:input.target.value})
                                    }}/>
                                </Cell>
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Teléfono celular 1</label>
                                    <input type="text" 
                                    readOnly={!this.state.isEditing ? true : false}
                                    defaultValue={this.state.company.cellContact1}
                                    onChange={(input)=>{
                                        this.setState({cellContact1:input.target.value})
                                    }}/>
                                </Cell>
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Contacto interno 2</label>
                                    <input type="text" 
                                    readOnly={!this.state.isEditing ? true : false}
                                    defaultValue={this.state.company.internalContact2}
                                    onChange={(input)=>{
                                        this.setState({internalContact2:input.target.value})
                                    }}/>
                                </Cell>
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Teléfono celular 2</label>
                                    <input type="text" 
                                    readOnly={!this.state.isEditing ? true : false}
                                    defaultValue={this.state.company.cellContact2}
                                    onChange={(input)=>{
                                        this.setState({cellContact2:input.target.value})
                                    }}/>
                                </Cell>
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Contacto interno 3</label>
                                    <input type="text" 
                                    readOnly={!this.state.isEditing ? true : false}
                                    defaultValue={this.state.company.internalContact3}
                                    onChange={(input)=>{
                                        this.setState({internalContact3:input.target.value})
                                    }}/>
                                </Cell>
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Teléfono celular 3</label>
                                    <input type="text" 
                                    readOnly={!this.state.isEditing ? true : false}
                                    defaultValue={this.state.company.cellContact3}
                                    onChange={(input)=>{
                                        this.setState({cellContact3:input.target.value})
                                    }}/>
                                </Cell>
                                
                                <Cell className="text-right" small={12} medium={12} large={12} >
                                   {this.buttonUpdate()} 
                                </Cell>

                            </Grid>

                            </form>
                    </Modal>  
                    
                </Cell>
            </Grid>
        )
    }
}