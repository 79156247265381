import React from 'react'

import { Grid, Cell, Button, TwoHandleSlider } from 'react-foundation';
import Modal from 'react-foundation-modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faRulerHorizontal, faTimes, faBuilding } from '@fortawesome/free-solid-svg-icons'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select'

import Axios from 'axios'
import config from '../../config/config'

const overlayStyle = {
    'backgroundColor': 'rgba(0,58,102,.45)'
    };

export default class OptionsClientsComponent extends React.Component{
    constructor(){
        super()


        this.state = {
            modalIsOpen: false,
            internalId:"-",
            email:"",
            password:"",
            confirmPassword:"",
            clientName:"",
            idCompanies:[],
            position:"",
            phone:"",
            cellphone:"",
            profile:"--",
            companiesShow:[],
            nickName:"",

        }


        this.drawOptions = this.drawOptions.bind(this)
        this.handleSubmitClient = this.handleSubmitClient.bind(this)
        this.addCompanies = this.addCompanies.bind(this)
        this.removeCompany = this.removeCompany.bind(this)
        this.drawCompanies = this.drawCompanies.bind(this)
        this.setOptions = this.setOptions.bind(this)

    }

    showPopup = (status) => {
        this.setState({
            modalIsOpen: status,
            companiesShow:[],
            idCompanies:[]
        });
    }

    drawCompanies(){

        if(this.state.companiesShow.length > 0){
            let aux
            let labelCompanies = this.state.companiesShow.map(company => {
                aux = aux === undefined ? 0 : aux+1
                return(
                    <span style={{marginLeft:3}} className={"button warning"} >
                    <FontAwesomeIcon style={{marginRight:5}} className="icon-arrow" icon={faBuilding} size={"1x"} color={"#003A66"}/> 
                    {company.companyName}
                    <a id={aux} style={{color:"black", fontWeight:"bold" ,marginLeft:5}} onClick={(label) => this.removeCompany(label.target.id)}>
                        X
                    </a></span>
                )  
            })
            return labelCompanies
        }else{
            return false
        }

    }

    removeCompany(position){
        
        let newArray = this.state.idCompanies
        newArray.splice(position,1)
        let show = this.state.companiesShow
        show.splice(position,1)
        const self = this
        self.setState({idCompanies:newArray, companiesShow: show})

    }

    addCompanies(company){
        let lastArray = this.state.idCompanies
        let companiesShow =  this.state.companiesShow
        if(!config.containsCompanies(companiesShow, JSON.parse(company))){
            company = JSON.parse(company)
            lastArray.push(company.idCompany)
            companiesShow.push(company)
            const self = this
            self.setState({idCompanies:lastArray, companiesShow:companiesShow})
        }else{
            toast.warn("Esa empresa ya fue agregada", {containerId: 'A'})
        }
    }

    setOptions(){
        if(this.props.companies.length > 0){
            var company = this.props.companies.map(companie => {
                return {
                    value:`{"idCompany":"${companie.idCompany}","companyName":"${companie.companyName}"}`,
                    label:companie.companyName
                }
            })
            return company
        }else{
            return false
        }
    }

    drawOptions(){

        if(this.props.companies.length > 0){
            var companie = this.props.companies.map(companie => {
                return(
                    <option value={`{"idCompany":"${companie.idCompany}","companyName":"${companie.companyName}"}`}>
                       {companie.companyName} 
                    </option>
                )
            })
            return companie
        }else{
            return false
        }

    }

    handleSubmitClient(event){
        event.preventDefault()

        const self = this

        if(self.state.password !== self.state.confirmPassword){
            toast.warn('Las contraseñas no coinciden.', {containerId: 'A'})
        }else{
            Axios.post(`${config.getIPAddress()}api/v1/users/clients`,{
                    internalId:self.state.internalId,
                    email:self.state.email,
                    password:self.state.password,
                    name:self.state.clientName,
                    company:self.state.idCompanies,
                    position:self.state.position,
                    phone:self.state.phone,
                    cellphone:self.state.cellphone,
                    profile:self.state.profile,
                    nickName:self.state.nickName
            }).then(response => {
                if(response.status == 200){
                    toast.success("Cliente registrado correctamente.", {containerId: 'A'})
                    self.props.onhandleclientRegistered()
                    self.showPopup()
                }

                

            }).catch(err => {

                if(err.response.status == 404){
                    toast.error("Ocurrió un problema verifica los campos he intetalo de nuevo,", {containerId: 'A'})
                }

                if(err.response.status == 304){
                    toast.error("El correo electrónico o nombre de usuario ya fue utilizado.", {containerId: 'A'})
                }
               
            })
        }
    }



    render(){
        return(
            <Grid className="cn-options">
                <Cell className="cn-search" small={4} medium={4} large={4}>
                    <input placeholder="Buscar" type="text" onChange={(input) => {
                        this.props.onhandleSearch(input.target.value)
                    }}/>
                </Cell>

                <Cell style={{paddingLeft:10}} small={3} medium={3} large={3}>
                </Cell>


                <Cell style={{textAlign:"right"}} small={5} medium={5} large={5}>
                    <Button style={{backgroundColor:"#003A66"}} onClick={() => this.showPopup(true)}>
                    <FontAwesomeIcon icon={faPlus} size={"1x"} color={"#fff"}/>
                    </Button>
                    <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.TOP_RIGHT} />
                    <Modal 
                        open={this.state.modalIsOpen}
                        closeModal={this.showPopup}
                        isModal={true}
                        size="small"
                        overlayStyle={overlayStyle} >

                        <form onSubmit={(self) => this.handleSubmitClient(self)} style={{textAlign:"left"}}>
                            <h3>Nuevo Cliente</h3>
                            <Grid>
                           
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Email*</label>
                                    <input type="text" onChange={(input)=>{
                                        this.setState({email:input.target.value})
                                    }}/>
                                </Cell>
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Usuario*</label>
                                    <input type="text" onChange={(input)=>{
                                        this.setState({nickName:input.target.value})
                                    }}/>
                                </Cell>
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Contraseña*<a href=""></a></label>
                                    <input type="password" onChange={(input)=>{
                                        this.setState({password:input.target.value})
                                    }}/>
                                </Cell>
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Confirmar contraseña*</label>
                                    <input type="password" onChange={(input)=>{
                                        this.setState({confirmPassword:input.target.value})
                                    }}/>
                                </Cell>
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Nombre*</label>
                                    <input type="text" onChange={(input)=>{
                                        this.setState({clientName:input.target.value})
                                    }}/>
                                </Cell>
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Puesto*</label>
                                    <input type="text" onChange={(input)=>{
                                        this.setState({position:input.target.value})
                                    }}/>
                                </Cell>
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Teléfono de oficina + ext</label>
                                    <input type="text" onChange={(input)=>{
                                        this.setState({phone:input.target.value})
                                    }}/>
                                </Cell>
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Teléfono celular</label>
                                    <input type="text" onChange={(input)=>{
                                        this.setState({cellphone:input.target.value})
                                    }}/>
                                </Cell>
                                <Cell className="cn-fr" small={12} medium={12} large={12}>
                                    <label>Empresa*</label>
                                    <Select options={this.setOptions()}
                                    onChange={(input)=>{
                                        if(input != null){
                                            this.addCompanies(input.value)
                                        }
                                    }} 
                                    isClearable={true}/>
                                </Cell>
                                <Cell className="cn-fr" small={12} medium={12} large={12}>
                                    {this.drawCompanies()}
                                </Cell>
                                <Cell className="cn-fr" small={10} medium={10} large={10}></Cell>
                                <Cell className="cn-fr" small={2} medium={2} large={2}>
                                    <Button>
                                        Guardar
                                    </Button>
                                </Cell>
                             
                            </Grid>


                        </form>

                        


                    </Modal>  
                </Cell>
            </Grid>
        )
    }
}