import React from 'react'


export default function NavigationVersion(){
    return(
        <div className="cn-navigation-version">
            <a target="_blank" href="http://ordux.mx/aviso/aviso-de-privacidad.pdf" >Políticas de Privacidad</a>
            <label>V1.0</label>
        </div>
    )
}