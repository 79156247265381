/**
 * Modules Dependencies
 */

 import React from 'react'
 import { Button, Colors, Grid, Cell, Sizes } from 'react-foundation';
 import {Redirect} from 'react-router-dom'

 import logo from '../../images/logo v.png';

 import "../../styles/header.css"
 
function Header(){
    return(
        <div className="cn-header">

            <div className="cn-headerLogo">
                <img src={logo} width={100} height={30}/>
            </div>

            <div className="cn-headerOptions">
                <Button style={{margin:0}} color={Colors.SECONDARY} size={Sizes.TINY} onClick={()=>{
                    sessionStorage.removeItem("authenticate")
                    window.location.reload(false);
                }}>
                    Cerrar Sesión
                </Button>
            </div>

        </div>
    )
}

export default Header