import React from 'react'

import OptionsCompaniesComponent from './optionsCompaniesComponent'
import MainTableCompaniesComponent from './mainTableCompaniesComponent'



export default class CompaniesMainComponet extends React.Component{
    constructor(){
        super()

        this.state = {
            companies:[],
            forceUpdate:false,
            search:""
        }

        this.handlecompanyRegistered = this.handlecompanyRegistered.bind(this)
        this.handleSearch = this.handleSearch.bind(this)

    }

    handlecompanyRegistered(){

        const self = this
        self.setState({forceUpdate:true})

    }

    handleSearch(textToSearch){
        this.setState({search:textToSearch})
    }


    render(){
        return(
            <div>
                <OptionsCompaniesComponent 
                    onhandleCompanyRegistered={this.handlecompanyRegistered} 
                    onhandleSearch={this.handleSearch}/>
                <MainTableCompaniesComponent 
                    onhandleCompanyRegistered={this.handlecompanyRegistered}
                    textToSearch={this.state.search}/>
                    
            </div>
        )
    }
}