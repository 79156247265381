import React from 'react'
import { Grid, Cell, Button } from 'react-foundation';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import Loader from 'react-loader-spinner'
import 'react-accessible-accordion/dist/fancy-example.css';

export default class RecordComponent extends React.PureComponent{
    constructor(){
        super()


    }

    render(){
        if(this.props.record != undefined){
            return(
                <Accordion>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            <b className="text-color-second">Información general del expediente</b>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <Grid>
                            <Cell small={6} medium={6} large={6}>
                                <label htmlFor="">No. Expediente Externo</label>
                                <div style={{padding:5}}>
                                    <input type="text" value={this.props.record.externalNumber} readOnly/>
                                </div>
                            </Cell>
                            <Cell small={12} medium={12} large={12}>
                                <label htmlFor="">Quejosos</label>
                                <div style={{padding:5}}>
                                    <textarea value={this.props.record.quejosos} readOnly cols="10" rows="3"></textarea>
                                </div>
                            </Cell>
                            <Cell style={{border:"1px solid rgba(128, 128, 128, .2)"}} small={12} medium={12} large={12}></Cell> 
                        </Grid>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            <b className="text-color-second">Información del cliente</b>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <Grid>
                            <Cell small={6} medium={6} large={6}>
                                <label htmlFor="">RFC Empresa</label>
                                <div style={{padding:5}}>
                                    <input type="text" value={this.props.record.rfc + " - " +this.props.record.companyName} readOnly/>
                                </div>
                            </Cell>
                            <Cell small={6} medium={6} large={6}>
                                <label htmlFor="">Juzgado</label>
                                <div style={{padding:5}}>
                                    <input type="text" value={this.props.record.court} readOnly/>
                                </div>
                            </Cell>
                            <Cell small={12} medium={12} large={12}>
                                <label htmlFor="">Asunto</label>
                                <div style={{padding:5}}>
                                    <textarea value={this.props.record.issue} cols={3} rows={2}></textarea>
                                </div>
                            </Cell>
                        </Grid>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
            )
        }else{
            return(
                <Loader
                            type="Puff"
                            color="#003A66"
                            height={100}
                            width={100}
                            timeout={10000} //3 secs
                        />
            )
        }
        
    }
}