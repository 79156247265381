import React from 'react'

import { Grid, Cell, Button  } from 'react-foundation';

import "../../styles/record.css"

import Axios from 'axios'
import config from '../../config/config'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faUser, faFolder} from '@fortawesome/free-solid-svg-icons'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const overlayStyle = {
    'backgroundColor': 'rgba(0,58,102,.45)'
    };

export default class ChangePasswordComponent extends React.Component{
    constructor(){
        super()

        this.state = {
            password:"",
            confirmPassword:""
        }


        this._handleChangePassword = this._handleChangePassword.bind(this)

    }

    UNSAFE_componentWillMount(){
        
    }

    _handleChangePassword(event){
        event.preventDefault()

        var dataUser = JSON.parse(sessionStorage.dataUser)

        const self = this
//toast.success("Expediente registrado correctamente.", {containerId: 'A'})
//toast.error("Ocurrió un problema verifica los campos he intetalo de nuevo,", {containerId: 'A'})
        if(this.state.password != this.state.confirmPassword){
            toast.warn("Las contraseñas no coinciden.", {containerId: 'A'})
        }else{
            Axios.put(`${config.getIPAddress()}api/v1/users/password`,{
                idUser:dataUser.id_user,
                password:self.state.password
        }).then(response => {
            if(response.status == 200){
                toast.success("Contraseña actualizada correctamente", {containerId: 'A'})
            } 
        }).catch(err => {
            //console.log(err)
        
            toast.error("Ocurrió un problema, si el problema persiste contacta al administrador,", {containerId: 'A'})
        })
        }

    }

    render(){
        return(
            <div style={{height:"100%", overflow:"hidden"}}>
                <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.TOP_RIGHT} />
                <Grid style={{maxHeight:"100%"}}>
                    <Cell small={12} medium={12} large={12} style={{overflow:"auto"}}>
                    <form onSubmit={(self) => this._handleChangePassword(self)} style={{textAlign:"left"}}>
                            <h4>Cambiar contraseña</h4>
                            <Grid>
                                <Cell className="cn-fr" small={4} medium={4} large={4}>
                                    <label>Contraseña</label>
                                    <input required type="password" onChange={(input)=>{
                                        this.setState({password:input.target.value})
                                    }}/>
                                </Cell>
                                <Cell className="cn-fr" small={4} medium={4} large={4}>
                                    <label>Confirmar contraseña</label>
                                    <input required type="password" onChange={(input)=>{
                                        this.setState({confirmPassword:input.target.value})
                                    }}/>
                                </Cell>
                                <Cell className="cn-fr" small={4} medium={4} large={4}></Cell>

                                <Cell className="cn-fr" small={2} medium={2} large={2}>
                                    <Button isDisabled={this.state.sending}>
                                        Reestablecer
                                    </Button>
                                </Cell>
                                <Cell className="cn-fr" small={10} medium={10} large={10}></Cell>
                                
                             
                            </Grid>


                        </form>
                    </Cell>
                </Grid>
            </div>
        )
    }
}