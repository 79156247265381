

import React from 'react'

import {
    BrowserRouter as Router,
    Switch,
    Route,
  } from "react-router-dom";


  import HeaderComponent from '../header/headerComponent'
  import NavigationViewComponent from './navigationViewComponent'

  import IndexUsersComponent from '../users/indexUsersComponent'
  import IndexRecordComponent from '../record/indexRecordComponents'
  import IndexHomeComponent from '../home/indexHomeComponent'
  import IndexConfigurationComponent from '../configurations/indexConfigurationsComponent'

  import IndexRecordUserComponent from '../recordUser/indexRecordUserComponent'

  import { Grid } from 'react-foundation';

 
  export default class NavigationRouting extends React.Component{
      constructor(){
          super()

          this.state = {
            idRole:"",
            userName:"",
            idRole:0,
            collapsed: false
        }


        this.renderSwitch = this.renderSwitch.bind(this)
      }

      UNSAFE_componentWillMount(){
        var dataUser = JSON.parse(sessionStorage.dataUser)
        const self = this
        if(dataUser.id_role == 2){
            self.setState({role: "Abogado", userName:dataUser.name, idRole:2})
        }else if(dataUser.id_role == 1){
            self.setState({role: "Cliente", userName:dataUser.name, idRole:1})
        }
        else if(dataUser.id_role == 3){
            self.setState({role: "Gerente", userName:dataUser.name, idRole:3})
        }
    }

    renderSwitch(){
        if(this.state.idRole != 0){
            if(this.state.idRole == 2){
                return(
                    <Switch>
                        <Route path="/configurations">
                            <IndexConfigurationComponent />
                        </Route>
                        <Route path="/record">
                            <IndexRecordComponent expanded={ this.state.collapsed }/>
                        </Route>
                        <Route path="/">
                            <IndexHomeComponent />
                        </Route>
                    </Switch>
                )
            }else if(this.state.idRole == 1){
                return(
                    <Switch>
                        <Route path="/configurations">
                            <IndexConfigurationComponent />
                        </Route>
                        <Route path="/recordU">
                            <IndexRecordUserComponent expanded={ this.state.collapsed }/>
                        </Route>
                        <Route path="/">
                            <IndexHomeComponent />
                        </Route>
                    </Switch>
                )
            }else if(this.state.idRole == 3){
                return(
                    <Switch>
                        <Route path="/configurations">
                            <IndexConfigurationComponent />
                        </Route>
                        <Route path="/users">
                            <IndexUsersComponent />
                        </Route>
                        <Route path="/recordAdmin">
                            <IndexRecordComponent expanded={ this.state.collapsed }/>
                        </Route>
                        <Route path="/">
                            <IndexHomeComponent />
                        </Route>
                    </Switch>
                )
            }
        }
    }

    handleToggleCollapse() {
        this.setState({collapsed: !this.state.collapsed})
    }

      render(){
          return(
            <Router>
            <div>
                <HeaderComponent />
                <Grid>
                    <NavigationViewComponent collapsed={this.state.collapsed} onToggleCollapse={() => this.handleToggleCollapse()}/>
                    
                    {this.renderSwitch()}

                </Grid>
            </div>
          </Router>
          )
      }
  }

