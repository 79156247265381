import React from 'react'
import { Grid, Cell, InputTypes, Button } from 'react-foundation'

import '../../styles/record.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faListAlt, faEye, faEdit, faExpandArrowsAlt} from '@fortawesome/free-solid-svg-icons'

import Modal from 'react-foundation-modal';

import Loader from 'react-loader-spinner'

import Axios from 'axios'
import config from '../../config/config'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const overlayStyle = {
    'backgroundColor': 'rgba(0,58,102,.45)'
    };

export default class MainTableComponent extends React.Component{

    constructor(){
        super()

        this.state = {
            idRecord:0,
            idClient:0,
            issues:[],
            records:[],
            modalIsOpen: false,
            title:"",
            hearingDate:"",
            status:"",
            observations:"",
            notes:"",
            edit_by:"",
            client_relation:"",
            externalNumber:"",
            priority:0,
            filter:["numberInternal"],
            stageFilter: 1,
            isEditing:false,
            anotherModalIsOpen:false,
            external_file_number:"",
            city:"",
            court:"",
            complain:"",
            idLawyer:"",
            issue:"",
            record:{}

        }


        this.drawRows = this.drawRows.bind(this)
        this.updateRecordSelected = this.updateRecordSelected.bind(this)
        this.updateRecordSelectedToEdit = this.updateRecordSelectedToEdit.bind(this)
        this.selectRow = this.selectRow.bind(this)
        this.getRecords = this.getRecords.bind(this)
        this.setWithOutChange = this.setWithOutChange.bind(this)
        this.drawActions = this.drawActions.bind(this)
        this.getIssues = this.getIssues.bind(this)
        this.drawIssues = this.drawIssues.bind(this)
        this.drawStages = this.drawStages.bind(this)
        this.drawLawyers = this.drawLawyers.bind(this)
        this.handleUpdateRecord = this.handleUpdateRecord.bind(this)

    }

    showPopup = (status) => {
        this.setState({
            modalIsOpen: status
        });
    }

    showPopupAnother = (status) => {
        this.setState({
            anotherModalIsOpen: status
        });
    }

    updateRecordSelected(idRecord, idClient, externalNumber){
        this.setState({idRecord:idRecord, idClient:idClient, externalNumber: externalNumber}, this.showPopup(true))
    }

    updateRecordSelectedToEdit(idRecord, idClient, externalNumber){
        this.setState({idRecord:idRecord, idClient:idClient, externalNumber: externalNumber})
    }

  

    selectRow(idRecord){
        this.props.onhandleSelectRecord(true, idRecord)
    }

    UNSAFE_componentWillMount(props){
        this.getIssues()
        this.getRecords("",this.props.filter, this.props.stageFilter)
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        this.getRecords(nextProps.textToSearch, nextProps.filter, nextProps.stageFilter)
    }

    getRecords(textToSearch, filter, stageFilter){
        var self = this

        var dataUser = JSON.parse(sessionStorage.dataUser)

        Axios.get(`${config.getIPAddress()}api/v1/files?total_rows=${1000}&current_page=${1}&idRole=${dataUser.id_role}&idClient=${dataUser.id_user}&filters=${JSON.stringify(filter)}&value=${textToSearch != undefined ? textToSearch : ""}`,{
            total_rows:1000,
            current_page:1
        }).then(response => {
           
            if(response.status == 200){
                let records = stageFilter == 0 ? response.data.data : (
                    stageFilter == 2 ? response.data.data.filter(d => d.stage == 0) : response.data.data.filter(d => d.stage == 1) 
                )
                self.setState({records})
            }
        
        }).catch(err =>{
        })
    }

    getIssues(){
        ///api/v1/issues

        const self = this
        Axios.get(`${config.getIPAddress()}api/v1/issues`,{
            total_rows:1000,
            current_page:1
        }).then(response => {
            if(response.status == 200){
                self.setState({issues: response.data.data})
            }
        }).catch(err =>{
        })

    }

    getRecord(idRecord){
        const self = this
        Axios.get(`${config.getIPAddress()}api/v1/files/detail/${idRecord}`,{
            total_rows:1000,
            current_page:1
        }).then(response => {
            if(response.status == 200){
                self.setState({record: response.data.result[0]})
                this.showPopupAnother(true)
            }
        }).catch(err =>{
        })

    }

    handleUpdateRecord(event){

        event.preventDefault()

        const self = this

        Axios.put(`${config.getIPAddress()}api/v1/files`,{
            idFile:self.state.idRecord,
            lawyer:self.state.idLawyer,
            city:self.state.city,
            court:self.state.court,
            stage:self.state.stage,
            complain:self.state.complain,
            issue:self.state.issue,
            externalNumber:self.state.external_file_number
    }).then(response => {
        if(response.status == 200){
            toast.success("Cliente actualizado correctamente.", {containerId: 'A'})
            self.props.onhandlerecordRegistered()
            self.showPopupAnother()
        }
        else if(response.status == 404){
            toast.error("Ocurrió un problema verifica los campos he intetalo de nuevo,", {containerId: 'A'})
        }

    }).catch(err => {
        toast.error("Ocurrió un problema, si el problema persiste contacta al administrador,", {containerId: 'A'})
    })

    }

    setWithChange(event){

        event.preventDefault()

        const self = this
        var dataUser = JSON.parse(sessionStorage.dataUser)
        let idRecord = this.state.idRecord

        Axios.post(`${config.getIPAddress()}api/v1/files/binnacle`,{
            isChange:1,
            id_files:idRecord,
            title:this.state.title,
            hearingDate:this.state.hearingDate,
            status:this.state.status,
            observations:this.state.observations,
            notes:this.state.notes,
            edit_by:dataUser.id_user,
            client_relation:this.state.idClient,
            externalNumber:this.state.externalNumber,
            priority:this.state.priority,

    }).then(response => {

        if(response.status == 200){
            toast.success("Expediente actualizado correctamente.", {containerId: 'A'})
            self.props.onhandlerecordRegistered()
            self.showPopup()
            window.location.reload(false);
        }

    }).catch(err => {
        if(err.response.status == 304){
            toast.error("Ocurrió un problema verifica los campos he intetalo de nuevo.", {containerId: 'A'})
        }else{
            toast.error("Ocurrió un problema, si el problema persiste contacta al administrador,", {containerId: 'A'})
        }
       
    })

    }

    setWithOutChange(idRecord){

        const self = this
        var dataUser = JSON.parse(sessionStorage.dataUser)

        Axios.post(`${config.getIPAddress()}api/v1/files/binnacle`,{
            isChange:0,
            id_files:idRecord,
            title:"",
            hearingDate:"",
            status:"",
            observations:"",
            notes:"",
            edit_by:dataUser.id_user,
            client_relation:"",
            internalNumber:"",
            priority:0,

    }).then(response => {

        if(response.status == 200){
            toast.success("Expediente actualizado correctamente, sin cambio.", {containerId: 'A'})
            self.props.onhandlerecordRegistered()
        }

        if(response.status == 404){
            toast.error("Ocurrió un problema verifica los campos he intetalo de nuevo,", {containerId: 'A'})
        }

    }).catch(err => {
        if(err){
            toast.error("Ocurrió un problema, si el problema persiste contacta al administrador,", {containerId: 'A'})
        } 
    })

        
    }

    drawActions(idRecord, idClient, externalNumber, displayDate){
        var dataUser = JSON.parse(sessionStorage.dataUser)

        if(dataUser.id_role == 2){
            return (
                <td>
                        <a className={displayDate != 0 ? "hidden" : ""} href="#" onClick={() => { 
                                this.updateRecordSelected(idRecord, idClient, externalNumber)
                                }}><FontAwesomeIcon icon={faListAlt} size="1x"/></a>
                        <a style={{marginLeft:"5px"}} href="#" onClick={() => { 
                                this.selectRow(idRecord)
                            }}><FontAwesomeIcon icon={faEye} size="1x"/></a>
                </td>
            )
        }else if(dataUser.id_role == 3){
            return(
                <td>
                        <a className={displayDate != 0 ? "hidden" : ""} href="#" onClick={() => { 
                                this.updateRecordSelected(idRecord, idClient, externalNumber)
                                }}><FontAwesomeIcon icon={faListAlt} size="1x"/></a>
                        <a style={{marginLeft:"5px"}} href="#" onClick={() => { 
                                this.selectRow(idRecord)
                            }}><FontAwesomeIcon icon={faEye} size="1x"/></a>
                        <a style={{marginLeft:"5px"}} href="#" onClick={() => { 
                                this.getRecord(idRecord)
                                this.updateRecordSelectedToEdit(idRecord, idClient, externalNumber)
                        }}><FontAwesomeIcon icon={faEdit} size="1x"/></a>
                </td>
            )
        }
    }

    drawIssues(){

        if(this.state.issues.length > 0){
           
            let rfcs = this.state.issues.map(issue=>{
                
                return(
                    <option value={issue.idIssue}>
                        {issue.issue}
                    </option>
                )
            })

            return rfcs

        }else{
            return false
        }
    }

    drawStages(){
        return (
            <>
                <option value={1} selected={this.state.record.stage == 1}>
                    Concluido
                </option>
                <option value={0} selected={this.state.record.stage == 0}>
                    En trámite
                </option>
            </>
        )
    }

    drawLawyers(){

        if(this.props.lawyers.length > 0){
            var lawyer = this.props.lawyers.map(lawyer => {
                return(
                    <option value={lawyer.id_user}>
                       {lawyer.name} 
                    </option>
                )
            })
            return lawyer
        }else{
            return false
        }

    }

    //1 actualización
    //0 sin actualización
    //2 sin cambio

    drawRows(){


        if(this.state.records.length > 0){
            var rows = this.state.records.map( (data, i) => {
                var status = ""

                if(data.display_date == 0){
                    status = "Sin Actualización"
                }else if(data.display_date == 1){
                    status = "Actualizado"
                }else if(data.display_date == 2){
                    status = "Sin cambio"
                }

                return(
                    <tr>
                        <td className="text-center">
                            <input type="checkbox" 
                            defaultChecked={data.display_date != 0 ? true : false} 
                            readOnly={data.display_date != 0 ? true : false}
                            disabled={data.display_date != 0 ? true : false}
                            onClick={()=>this.setWithOutChange(data.idRecord)}/>
                        </td>
                        <td className="text-center">
                            <span className={data.priority ? "alert-priority" : "normal-priority"}></span>
                        </td>
                        <td>
                            <span>{data.internalNumber}</span>
                        </td>
                        <td>
                            <span>{data.externalNumber}</span>
                        </td>
                        <td>
                            <span>{data.court} - {data.city}</span>
                        </td>
                        <td>
                            <span>{data.companyName}</span>
                        </td>
                        <td>
                            <span>{data.quejosos}</span>
                        </td>
                        <td>
                            <span>{data.cl_issue}</span>
                        </td>
                        <td>
                            <span>{data.stage == 0 ? 'En trámite' : 'Concluido'}</span>
                        </td>
                        <td>
                            <span>{data.registerDate}</span>
                        </td>
                        <td>
                            <span>{status}</span>
                        </td>
                        {this.drawActions(data.idRecord, data.id_client, data.externalNumber, data.display_date)}
                    </tr>
                )
            })

            return rows

        }else{
            return(
                <tr>
                    <td colSpan={10} style={{textAlign:"center"}}>
                        <b>No hay datos que mostrar.</b>
                        <Loader
                            type="Puff"
                            color="#003A66"
                            height={100}
                            width={100}
                            timeout={10000} //3 secs
                        />
                    </td>
                </tr>
            )
        }

    }


    render(){
        return(
            <Grid>
                <Cell small={12} medium={12} large={12}>
                    <table className="hover unstriped">
                        <thead>
                            <tr>
                                <th>Check</th>
                                <th>Priority</th>
                                <th># expediente interno</th>
                                <th># expediente externo</th>
                                <th>Juzgado o sala</th>
                                <th>Empresa</th>
                                <th>Quejoso(s)</th>
                                <th>Asunto</th>
                                <th>Etapa</th>
                                <th>Última actualización</th>
                                <th>Estatus</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.drawRows()}
                        </tbody>
                    </table>

                    <Modal 
                        open={this.state.modalIsOpen}
                        closeModal={this.showPopup}
                        isModal={true}
                        size="small"
                        overlayStyle={overlayStyle} >

                        <form onSubmit={(self) => this.setWithChange(self)} style={{textAlign:"left"}}>
                            <h3>Nueva Bitácora</h3>
                            <Grid>

                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    Acciones*
                                    <select name="" id="" onChange={(input)=>{
                                        this.setState({priority:input.target.value})
                                    }}>
                                        <option value="" >
                                            -Seleccionar-
                                        </option>
                                        <option value="0">
                                            Prioridad Normal
                                        </option>
                                        <option value="1">
                                            Enviar a Gerentes
                                        </option>
                                    </select>
                                </Cell>

                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Titúlo de bitácora*</label>
                                    <input type="text" onChange={(input)=>{
                                        this.setState({title:input.target.value})
                                    }}/>
                                </Cell>
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Fecha de audiencia</label>
                                    <input type="date" onChange={(input)=>{
                                        this.setState({hearingDate:input.target.value})
                                    }}/>
                                </Cell>
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Estatus para cliente*</label>
                                    <input type="text" onChange={(input)=>{
                                        this.setState({status:input.target.value})
                                    }}/>
                                </Cell>
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Observaciones</label>
                                    <textarea name="" id="" cols="30" rows="10" onChange={(input)=>{
                                        this.setState({observations:input.target.value})
                                    }}></textarea>
                                </Cell>
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Notas</label>
                                    <textarea name="" id="" cols="30" rows="10" onChange={(input)=>{
                                        this.setState({notes:input.target.value})
                                    }}></textarea>
                                </Cell>
                                <Cell className="cn-fr" small={10} medium={10} large={10}></Cell>
                                <Cell className="cn-fr" small={2} medium={2} large={2}>
                                    <Button>
                                        Guardar
                                    </Button>
                                </Cell>
                             
                            </Grid>


                        </form>
                    </Modal>  


                    <Modal 
                        open={this.state.anotherModalIsOpen}
                        closeModal={this.showPopupAnother}
                        isModal={true}
                        size="small"
                        overlayStyle={overlayStyle} >

                        <form onSubmit={(self) => this.handleUpdateRecord(self)} style={{textAlign:"left"}}>
                            <h3>Actualizar expediente</h3>
                            <Grid>
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>No. de expediente externo</label>
                                    <input type="text"
                                    defaultValue={this.state.record.externalNumber} 
                                    onChange={(input)=>{
                                        this.setState({external_file_number:input.target.value})
                                    }}/>
                                </Cell>

                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Ciudad(Juzgado)</label>
                                    <input type="text" 
                                    defaultValue={this.state.record.city}
                                    onChange={(input)=>{
                                        this.setState({city:input.target.value})
                                    }}/>
                                </Cell>
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Juzgado</label>
                                    <input type="text" 
                                    defaultValue={this.state.record.court}
                                    onChange={(input)=>{
                                        this.setState({court:input.target.value})
                                    }}/>
                                </Cell>
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Reasignar abogado</label>
                                    <select onChange={(input)=>{
                                        this.setState({idLawyer:input.target.value})
                                    }}>
                                        <option value="">--Seleccionar Abogado--</option>
                                        {this.drawLawyers()}
                                    </select>
                                </Cell>
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Asunto</label>
                                    <select name="" id="" onChange={(input)=>{
                                        this.setState({issue:input.target.value})
                                    }}>
                                        <option value="">-Seleccionar asunto-</option>
                                        {this.drawIssues()}
                                    </select>
                                </Cell>
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Etapa</label>
                                    <select name="" id="" onChange={(input)=>{
                                        this.setState({stage:input.target.value})
                                    }}>
                                        <option value="">-Seleccionar etapa-</option>
                                        {this.drawStages()}
                                    </select>
                                </Cell>
                                <Cell className="cn-fr" small={12} medium={12} large={12}>
                                    <label>Quejoso(s)</label>
                                    
                                    <input type="text" 
                                    defaultValue={this.state.record.quejosos}
                                    onChange={(input)=>{
                                        this.setState({complain:input.target.value})
                                    }}/>
                                </Cell>
                                <Cell className="cn-fr" small={10} medium={10} large={10}></Cell>
                                <Cell className="cn-fr" small={2} medium={2} large={2}>
                                    <Button>
                                        Guardar
                                    </Button>
                                </Cell>
                             
                            </Grid>


                        </form>

                        


                    </Modal>  
                </Cell>
            </Grid>
        )
    }
}