import React from 'react'

import OptionsSystemMainComponent from './optionsUsersSystemMainComponent'

import MainTableUsersSystemComponent from './mainTableUsersSystemComponent'

export default class ClientsMainComponet extends React.Component{
    constructor(){
        super()

        this.state = {
            forceUpdate:false,
            search:""
        }

        this.handleuserRegistered = this.handleuserRegistered.bind(this)
        this.handleSearch = this.handleSearch.bind(this)
    }

    handleuserRegistered(){

        const self = this

        self.setState({forceUpdate:true})
    }

    handleSearch(textToSearch){
        this.setState({search:textToSearch})
    }

    render(){
        return(
            <div>
                <OptionsSystemMainComponent 
                    onhandleuserRegistered={this.handleuserRegistered}
                    onhandleSearch={this.handleSearch}/>
                <MainTableUsersSystemComponent 
                    onhandleuserRegistered={this.handleuserRegistered}
                    textToSearch={this.state.search}/>
            </div>
        )
    }
}