import React from 'react'

import { Grid, Cell, Button } from 'react-foundation';
// <MainTargetsComponent onhandleSelectRecord={this.handleSelectRecord}/>

import "../../styles/record.css"

import Axios from 'axios'
import config from '../../config/config'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faUser, faFolder} from '@fortawesome/free-solid-svg-icons'

import ChangePasswordComponent from './changePasswordComponent'

export default class IndexConfigurationsComponent extends React.Component{
    constructor(){
        super()

    }

    render(){
        return(
            <Cell small={10} medium={10} large={10} style={{overflow:"hidden", maxHeight:"94vh"}}>
                    <div className="cn-main-titles">
                        <h4 className="text-color-main">
                        <FontAwesomeIcon style={{marginLeft:10}} className="icon-arrow" icon={faFolder} size={"1x"} color={"#BFA568"}/>
                        <span style={{marginLeft:6}}>Configuraciones</span></h4>  
                    </div>
                    <div className="cn-main-components cn-record">
                        <ChangePasswordComponent />
                    </div>
            </Cell>
        )
    }
}