
/**
 * Import Dependenciens
 */

import React, {Component} from 'react'

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";

  import { Button, Colors, Grid, Cell } from 'react-foundation';

  import axios from 'axios'

  /**Import Components*/
  import logo from '../../images/logo v.png';


   /**Import Utilities*/

   import config from '../../config/config'
   
//192.168.0.10:2406
//api/v1/users/auth
//GET email-password
//diego@gmail.com - frank

   import "../../styles/logIn.css"



export default class IndexLogInComponent extends Component{

    constructor(){
        super()


        this.state = {
            user:"",
            password:"",
            messageToAlert:""
        }

        this.handleSubmitLogIn = this.handleSubmitLogIn.bind(this)
        this.drawAlerts = this.drawAlerts.bind(this)

    }

    handleSubmitLogIn(event){
        event.preventDefault()
        const self = this
        
        if(self.state.user === "" || 
           self.state.password === ""){
            let message = "Todos los campos marcados con '*' son requeridos"
            self.setState({messageToAlert:message})
        }else{
            self.setState({messageToAlert: ""})

            /*this.props.onHandleAuth(true)
            sessionStorage.setItem("authenticate", true)*/
            let uri = `${config.getIPAddress()}api/v1/login`
           axios.post(uri,{
                email:self.state.user,
                password:self.state.password
            })
            .then(response => {
                if(response.status === 200){
                    sessionStorage.setItem("dataUser", JSON.stringify(response.data.res[0]))
                    sessionStorage.setItem("authenticate", true)
                    this.props.onHandleAuth(true)
                }
                
            })
            .catch(err => {
                //console.log(err)
                alert("Usuario no autorizado, revisa tus credenciales o contacta con el administrador.")
                this.props.onHandleAuth(false)
            })
                //Se ejecuta la solicitud
        }


    }

    drawAlerts(){
        if(this.state.messageToAlert !== ""){
            return(
                <div>
                    <p className={"text-color-alert"}>
                        {this.state.messageToAlert}
                    </p>
                </div>
            )
        }else{
            return false
        }
    }

    render(){
        return(
        <div className="height-body">
            <div className="bg-image">

            <Grid className="display bg-color-white cn-main">
                <Cell className="cn-first" small={2} medium={6} large={6}>
                    
                    <div className="cn-logo">
                        <img src={logo} width={253} height={223}/>
                    </div>

                    <div className="cn-slogan">
                        <h4 className="txt-title text-color-main">Plataforma Sistema Legal (SILEG)</h4>
                    </div>
                    <div className="cn-description">
                        <p className="txt-description" style={{textAlign:"center"}}>
                      
                        </p>
                    </div> 
                </Cell>

                <Cell className="cn-second" small={2} medium={6} large={6}>
                    
                    <div className="cn-session">
                        <h4 className="txt-title text-color-main" style={{fontSize:48}}>Iniciar Sesión</h4>
                    </div>

                    <form className="cn-form" onSubmit={(self) => this.handleSubmitLogIn(self)}>
                        <label className="txt-label text-color-second" htmlFor="">Usuario*</label>
                        <input type="text" onChange={(input)=>{
                            this.setState({user:input.target.value})
                        }}/>
                        <label className="txt-label text-color-second" htmlFor="">Contraseña*</label>
                        <input type="password" onChange={(input)=>{
                            this.setState({password:input.target.value})
                        }}/>
                        <Button style={{backgroundColor:"#003A66", width:"100%", marginTop:"20px"}}>
                            Iniciar Sesión
                        </Button>
                        <div style={{width:"100%", textAlign:"center"}}>
                            <a target="_blank" href="http://ordux.mx/aviso/aviso-de-privacidad.pdf" >Políticas de Privacidad</a>
                        </div>
                    </form>
                    {this.drawAlerts()}
                </Cell>
            </Grid>
            </div>
        </div>
        )
    }




}