import React from 'react'


import { Grid, Cell } from 'react-foundation';
// <MainTargetsComponent onhandleSelectRecord={this.handleSelectRecord}/>

import "../../styles/record.css"


import MainTableComponent from './mainTableComponent'
import DetailRecordComponent from './detailRecordComponent'
import OptionsComponent from './optionsComponent'

import Axios from 'axios'
import config from '../../config/config'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faUser, faFolder} from '@fortawesome/free-solid-svg-icons'


export default class IndexRecordUserComponent extends React.Component{

    constructor(){
        super()


        this.state = {
            selectedRecord:false,
            idRecord:0,
            textToSearch:"",
            filter:["numberExternal"],
            stageFilter: 0
        }


        this.drawComponents = this.drawComponents.bind(this)
        this.handleSelectRecord = this.handleSelectRecord.bind(this)
        this.handleSearch = this.handleSearch.bind(this)
        this.handleFilter = this.handleFilter.bind(this)
        this.handleStageFilter = this.handleStageFilter.bind(this)
    }

    handleSearch(textToSearch){
        this.setState({search:textToSearch})
    }

    handleFilter(filter){
        this.setState({filter:[filter]})
    }

    handleStageFilter(filter){
   //console.log(filter)
        this.setState({stageFilter: filter})
    }

    handleSelectRecord(state, idRecord){
        this.setState({selectedRecord:state, idRecord})
    }

    drawComponents(){
        if(!this.state.selectedRecord){
            return(
                <div>
                    <label className="txt-instructions">Visualiza las actualizaciones de cada uno de tus expedientes. </label>
                    <OptionsComponent  
                        onhandleSearch={this.handleSearch}
                        onhandleFilter={this.handleFilter}
                        onhandleStageFilter={this.handleStageFilter}
                        stageFilter={this.state.stageFilter}
                    />
                    <MainTableComponent
                        onhandleSelectRecord={this.handleSelectRecord}
                        textToSearch={this.state.search}
                        filter={this.state.filter}
                        stageFilter={this.state.stageFilter}
                    />
                </div>
            )
        }else{
            return <DetailRecordComponent idRecord={this.state.idRecord} onhandleSelectRecord={this.handleSelectRecord}/>
        }
    }

    render(){
        //console.log('expanded', this.props.expanded)

        return(
            <Cell small={this.props.expanded ? 11 : 10} medium={this.props.expanded ? 11 : 10} large={this.props.expanded ? 11 : 10} style={{overflow:"hidden", maxHeight:"94vh"}}>
                    <div className="cn-main-titles">
                        <h4 className="text-color-main">
                        <FontAwesomeIcon style={{marginLeft:10}} className="icon-arrow" icon={faFolder} size={"1x"} color={"#BFA568"}/>
                        <span style={{marginLeft:6}}>Expedientes</span></h4>  
                    </div>
                    <div className="cn-main-components cn-record">
                        {this.drawComponents()}
                    </div>
                </Cell>
        )
    }

}