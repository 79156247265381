import React from 'react'

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";

  import "../../styles/navigation.css"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faUser, faFolder, faCogs} from '@fortawesome/free-solid-svg-icons'

export default class NavigationItems extends React.Component{

    constructor(){
        super()


        this.drawItems = this.drawItems.bind(this)
    }


    drawItems(){
        if(this.props.role != undefined){
            if(this.props.role == 2){
                return(
                    <ul>
                        <label className="txt-menu">Menú</label>
                        <li>
                            <Link className="item" to="/">
                            <FontAwesomeIcon style={{marginLeft: this.props.collapsed ? '30px': 'auto'}} className="icon-arrow" icon={faHome} size={"1x"} color={"#003A66"}/>
                                <span style={{marginLeft:10}}>{this.props.collapsed ? '' : 'Inicio'}</span>
                            </Link>
                        </li>
                        <li>
                            <Link className="item"  to="/record">
                                <FontAwesomeIcon style={{marginLeft: this.props.collapsed ? '30px': 'auto'}} className="icon-arrow" icon={faFolder} size={"1x"} color={"#003A66"}/>
                                <span style={{marginLeft:10}}>{this.props.collapsed ? '' : 'Expedientes'}</span>
                            </Link>
                        </li>
                        <li>
                            <Link className="item" to="/configurations">
                            <FontAwesomeIcon style={{marginLeft: this.props.collapsed ? '30px': 'auto'}} className="icon-arrow" icon={faCogs} size={"1x"} color={"#003A66"}/>
                                <span style={{marginLeft:10}}>{this.props.collapsed ? '' : 'Configuraciones'}</span>
                            </Link>
                        </li>
                    </ul>
                )
            }else if(this.props.role == 1){
                return(
                    <ul>
                        <label className="txt-menu">Menú</label>
                        <li>
                            <Link className="item" to="/">
                            <FontAwesomeIcon style={{marginLeft: this.props.collapsed ? '30px': 'auto'}} className="icon-arrow" icon={faHome} size={"1x"} color={"#003A66"}/>
                                <span style={{marginLeft:10}}>{this.props.collapsed ? '' : 'Inicio'}</span>
                            </Link>
                        </li>
                        <li>
                            <Link className="item"  to="/recordU">
                                <FontAwesomeIcon style={{marginLeft: this.props.collapsed ? '30px': 'auto'}} className="icon-arrow" icon={faFolder} size={"1x"} color={"#003A66"}/>
                                <span style={{marginLeft:10}}>{this.props.collapsed ? '' : 'Expedientes'}</span>
                            </Link>
                        </li>
                        <li>
                            <Link className="item" to="/configurations">
                            <FontAwesomeIcon style={{marginLeft: this.props.collapsed ? '30px': 'auto'}} className="icon-arrow" icon={faCogs} size={"1x"} color={"#003A66"}/>
                                <span style={{marginLeft:10}}>{this.props.collapsed ? '' : 'Configuraciones'}</span>
                            </Link>
                        </li>
                    </ul>
                )
            }else if(this.props.role == 3){
                return(
                    <ul>
                        <label className="txt-menu">Menú</label>
                        <li>
                            <Link className="item" to="/">
                            <FontAwesomeIcon style={{marginLeft: this.props.collapsed ? '30px': 'auto'}} className="icon-arrow" icon={faHome} size={"1x"} color={"#003A66"}/>
                                <span style={{marginLeft:10}}>{this.props.collapsed ? '' : 'Inicio'}</span>
                            </Link>
                        </li>
                        <li>
                            <Link className="item"  to="/users">
                                <FontAwesomeIcon style={{marginLeft: this.props.collapsed ? '30px': 'auto'}} className="icon-arrow" icon={faUser} size={"1x"} color={"#003A66"}/>
                                <span style={{marginLeft:10}}>{this.props.collapsed ? '' : 'Usuarios'}</span>
                            </Link>
                        </li>
                        <li>
                            <Link className="item"  to="/recordAdmin">
                                <FontAwesomeIcon style={{marginLeft: this.props.collapsed ? '30px': 'auto'}} className="icon-arrow" icon={faFolder} size={"1x"} color={"#003A66"}/>
                                <span style={{marginLeft:10}}>{this.props.collapsed ? '' : 'Expedientes'}</span>
                            </Link>
                        </li>
                        <li>
                            <Link className="item" to="/configurations">
                            <FontAwesomeIcon style={{marginLeft: this.props.collapsed ? '30px': 'auto'}} className="icon-arrow" icon={faCogs} size={"1x"} color={"#003A66"}/>
                                <span style={{marginLeft:10}}>{this.props.collapsed ? '' : 'Configuraciones'}</span>
                            </Link>
                        </li>
                    </ul>
                )
            }
        }
    }


    render(){
        return(
            <div>
                {this.drawItems()}
            </div>
        )
    }
}