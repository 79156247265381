import React from 'react'
import { Grid, Cell, InputTypes, Button } from 'react-foundation'

import '../../styles/record.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faListAlt, faEye} from '@fortawesome/free-solid-svg-icons'

import Modal from 'react-foundation-modal';

import Loader from 'react-loader-spinner'

import Axios from 'axios'
import config from '../../config/config'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const overlayStyle = {
    'backgroundColor': 'rgba(0,58,102,.45)'
    };

export default class MainTableComponent extends React.Component{


    constructor(){
        super()


        this.state = {
            records:[],
            filter:["numberInternal"],
            stageFilter: 0
        }

        this.getRecords = this.getRecords.bind(this)
        this.selectRow = this.selectRow.bind(this)
    }


    UNSAFE_componentWillMount(){
        this.getRecords("",this.props.filter, this.props.stageFilter)
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        this.getRecords(nextProps.textToSearch, nextProps.filter, nextProps.stageFilter)
    }


    getRecords(textToSearch, filter, stageFilter){

        var self = this

        var dataUser = JSON.parse(sessionStorage.dataUser)

        Axios.get(`${config.getIPAddress()}api/v1/files?total_rows=${1000}&current_page=${1}&idRole=${dataUser.id_role}&idClient=${dataUser.id_user}&filters=${JSON.stringify(filter)}&value=${textToSearch != undefined ? textToSearch : ""}`,{
            total_rows:1000,
            current_page:1
        }).then(response => {
            if(response.status == 200){
                let records = stageFilter == 0 ? response.data.data : (
                    stageFilter == 2 ? response.data.data.filter(d => d.stage == 0) : response.data.data.filter(d => d.stage == 1) 
                )
                self.setState({records})
            }
        
        }).catch(err =>{
        })
    }

    selectRow(idRecord){
        this.props.onhandleSelectRecord(true, idRecord)
    }

    drawRows(){


        if(this.state.records.length > 0){
            var rows = this.state.records.map( data => {
                var status = ""

                if(data.display_date == 0){
                    status = "Sin Actualización"
                }else if(data.display_date == 1){
                    status = "Actualizado"
                }else if(data.display_date == 2){
                    status = "Sin cambio"
                }


                return(
                    <tr>
                        <td>
                            <span>{data.externalNumber}</span>
                        </td>
                        <td>
                            <span>{data.court} - {data.city}</span>
                        </td>
                        <td>
                            <span>{data.companyName}</span>
                        </td>
                        <td>
                            <span>{data.quejosos}</span>
                        </td>
                        <td>
                            <span>{data.cl_issue}</span>
                        </td>
                        <td>
                            <span>{data.registerDate}</span>
                        </td>
                        <td>
                            <span>{data.stage == 0 ? 'En trámite' : 'Concluido'}</span>
                        </td>
                        <td>
                            <span>{status}</span>
                        </td>
                        <td>
                            <a style={{marginLeft:"5px"}} href="#" onClick={() => { 
                                this.selectRow(data.idRecord)
                            }}><FontAwesomeIcon icon={faEye} size="1x"/></a>
                        </td>
                    </tr>
                )
            })

            return rows

        }else{
            return(
                <tr>
                    <td colSpan={5} style={{textAlign:"center"}}>
                        <b>No hay datos que mostrar.</b>
                        <Loader
                            type="Puff"
                            color="#003A66"
                            height={100}
                            width={100}
                            timeout={10000} //3 secs
                        />
                    </td>
                </tr>
            )
        }

    }



    render(){
        return(
            <Grid>
                <Cell small={12} medium={12} large={12}>
                    <table className="hover unstriped">
                        <thead>
                            <tr>
                                <th># Expediente</th>
                                <th>Juzgado o sala</th>
                                <th>Empresa</th>
                                <th>Quejoso(s)</th>
                                <th>Asunto</th>
                                <th>Última actualización</th>
                                <th>Etapa</th>
                                <th>Estatus</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.drawRows()}
                        </tbody>
                    </table>

                </Cell>
            </Grid>
        )
    }

}