import React from 'react'
import { Grid, Cell, InputTypes, Button } from 'react-foundation'

import '../../styles/record.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faListAlt, faEye, faEdit, faBuilding} from '@fortawesome/free-solid-svg-icons'

import Modal from 'react-foundation-modal';
import Loader from 'react-loader-spinner'

import Axios from 'axios'
import config from '../../config/config'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const overlayStyle = {
    'backgroundColor': 'rgba(0,58,102,.45)'
    };

export default class MainTableComponent extends React.Component{

    constructor(){
        super()


        this.state = {
            idRecord:0,
            clients:[],
            modalIsOpen: false,
            textToSearch:"",
            client:{},
            title:"",
            isEditing:false,
            name:"",
            position:"",
            phone:"",
            cellphone:"",
            password:"",
            nickName:"",
            id_user:0,
            companies:[],
            idCompanies:[],
            companiesShow:[]

        }


        this.drawRows = this.drawRows.bind(this)
        this.updateRecordSelected = this.updateRecordSelected.bind(this)
        this.selectRow = this.selectRow.bind(this)
        this.getClients = this.getClients.bind(this)
        this.getClients = this.getClients.bind(this)
        this.buttonUpdate = this.buttonUpdate.bind(this)
        this.handleSubmitUser = this.handleSubmitUser.bind(this)
        this.drawModal = this.drawModal.bind(this)
        this.addCompanies = this.addCompanies.bind(this)
        this.removeCompany = this.removeCompany.bind(this)
        this.drawCompanies = this.drawCompanies.bind(this)
        this.drawOptions = this.drawOptions.bind(this)

    }

    drawCompanies(){

        if(this.state.companiesShow.length > 0){
            let aux
            let labelCompanies = this.state.companiesShow.map(company => {
                aux = aux === undefined ? 0 : aux+1
                return(
                    <span style={{marginLeft:3}} className={"button warning"} >
                        <FontAwesomeIcon style={{marginRight:5}} className="icon-arrow" icon={faBuilding} size={"1x"} color={"#003A66"}/> 
                        {company.companyName}
                    <a  id={aux} style={{color:"black", fontWeight:"bold" ,marginLeft:5, display: this.state.isEditing ? "inline-block" : "none"}} onClick={(label) => this.removeCompany(label.target.id)}>
                        X
                    </a></span>
                )  
            })
            return labelCompanies
        }else{
            return false
        }

    }

    removeCompany(position){
        
        let newArray = this.state.idCompanies
        newArray.splice(position,1)
        let show = this.state.companiesShow
        show.splice(position,1)
        const self = this
        self.setState({idCompanies:newArray, companiesShow: show})

    }

    addCompanies(company){
        let lastArray = this.state.idCompanies
        let companiesShow =  this.state.companiesShow

        if(!config.containsCompanies(companiesShow, JSON.parse(company))){
            company = JSON.parse(company)
            lastArray.push(company.idCompany)
            companiesShow.push(company)
            const self = this
            self.setState({idCompanies:lastArray, companiesShow:companiesShow})
        }else{
            toast.warn("Esa empresa ya fue agregada", {containerId: 'A'})
        }
        
        
    }

    showPopup = (status) => {
        this.setState({
            modalIsOpen: status
        });
    }

    updateRecordSelected(idRecord){
        this.setState({idRecord:idRecord}, this.showPopup(true))
    }

    selectRow(idRecord){
        this.props.onhandleSelectRecord(true, idRecord)
    }

    UNSAFE_componentWillMount(){
        this.getClients()
  
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        this.getClients(nextProps.textToSearch)
      
    }

    handleSubmitUser(event){
        event.preventDefault()

        const self = this

        Axios.put(`${config.getIPAddress()}api/v1/users/clients`,{
            id:self.state.id_user,
            name:self.state.name,
            position:self.state.position,
            phone:self.state.phone,
            cellphone:self.state.cellphone,
            companies:self.state.idCompanies,
            password:self.state.password,
            nickName:self.state.nickName
    }).then(response => {
        if(response.status == 200){
            toast.success("Cliente actualizado correctamente.", {containerId: 'A'})
            self.props.onhandleclientRegistered()
            self.showPopup()
        }
        else if(response.status == 404){
            toast.error("Ocurrió un problema verifica los campos he intetalo de nuevo,", {containerId: 'A'})
        }

    }).catch(err => {
        //console.log(err)
        toast.error("Ocurrió un problema, si el problema persiste contacta al administrador,", {containerId: 'A'})
    })
    }

    showPopup = (status) => {
        this.setState({
            modalIsOpen: status
        });
    }

    getClients(textToSearch){
        var self = this

        Axios.get(`${config.getIPAddress()}api/v1/users/clients?total_rows=${1000}&current_page=${1}&user_name=${textToSearch != undefined ? textToSearch : ""}`,{
            total_rows:1000,
            current_page:1
        }).then(response => {

            if(response.status == 200){
                self.setState({clients: response.data.data})
            }
        
        }).catch(err =>{
            //console.log(err)
        })
    }

    getClient(idClient){

        const self = this

        self.setState({client:{}, id_user:0})

        Axios.get(`${config.getIPAddress()}api/v1/users/clients/${idClient}`,{
            total_rows:1000,
            current_page:1
        }).then(response => {
            if(response.status == 200){
                let idCompanies = []
                response.data.res[0].companies.map(company => {

                    idCompanies.push(""+company.idCompany)
                
                })
                self.setState({client: response.data.res[0], id_user:response.data.res[0].id_user, companiesShow:response.data.res[0].companies, idCompanies:idCompanies}, this.showPopup(true))
            }
        
        }).catch(err =>{
            //console.log(err)
        })

    }

    drawOptions(){

        if(this.props.companies.length > 0){
            var companie = this.props.companies.map(companie => {
                return(
                    <option value={`{"idCompany":"${companie.idCompany}","companyName":"${companie.companyName}"}`}>
                       {companie.companyName} 
                    </option>
                )
            })
            return companie
        }else{
            return false
        }

    }

    drawModal(){
        if(this.state.client != undefined){
            return(
                <Modal 
                        open={this.state.modalIsOpen}
                        closeModal={this.showPopup}
                        isModal={true}
                        size="small"
                        overlayStyle={overlayStyle} >

                        <form onSubmit={(self) => this.handleSubmitUser(self)} style={{textAlign:"left"}}>
                            <h3>{this.state.title}</h3>
                            <Grid>
                           
                                
                                <Cell style={{display:this.state.isEditing ? "none" : "inline-block"}} className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Email</label>
                                    <input type="text" 
                                    readOnly={!this.state.isEditing ? true : false} 
                                    defaultValue={this.state.client.email}
                                    />
                                </Cell>
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Nombre</label>
                                    <input type="text" 
                                    readOnly={!this.state.isEditing ? true : false}  
                                    defaultValue={this.state.client.userName}
                                    onChange={(input)=>{
                                        this.setState({name:input.target.value})
                                    }}/>
                                </Cell>
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Usuario</label>
                                    <input type="text" 
                                    readOnly={!this.state.isEditing ? true : false}  
                                    defaultValue={this.state.client.nickName}
                                    onChange={(input)=>{
                                        this.setState({nickName:input.target.value})
                                    }}/>
                                </Cell>
                                <Cell style={{display:this.state.isEditing ? "inline-block" : "none"}} className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Contraseña</label>
                                    <input type="password" 
                                    readOnly={!this.state.isEditing ? true : false}
                                    onChange={(input)=>{
                                        this.setState({password:input.target.value})
                                    }}/>
                                </Cell>
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Puesto</label>
                                    <input type="text" 
                                    readOnly={!this.state.isEditing ? true : false}  
                                    defaultValue={this.state.client.position}
                                    onChange={(input)=>{
                                        this.setState({position:input.target.value})
                                    }}/>
                                </Cell>
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Teléfono de oficina + ext</label>
                                    <input type="text" 
                                    readOnly={!this.state.isEditing ? true : false}  
                                    defaultValue={this.state.client.phone}
                                    onChange={(input)=>{
                                        this.setState({phone:input.target.value})
                                    }}/>
                                </Cell>
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Teléfono celular</label>
                                    <input type="text" 
                                    readOnly={!this.state.isEditing ? true : false}  
                                    defaultValue={this.state.client.cell}
                                    onChange={(input)=>{
                                        this.setState({cellphone:input.target.value})
                                    }}/>
                                </Cell>

                                <Cell style={{display: this.state.isEditing ? "inline-block" : "none"}} className="cn-fr" small={12} medium={12} large={12}>
                                    <label>Empresa</label>
                                    <select name="" id="" onChange={(input)=>{
                                        this.addCompanies(input.target.value)
                                        input.target.value = ""
                                        //this.setState({idCompany:input.target.value})
                                    }}>
                                        <option value="">-Seleccionar Empresa-</option>
                                        {this.drawOptions()} 
                                    </select>
                                </Cell>

                                <Cell className="cn-fr" small={12} medium={12} large={12}>
                                    {this.drawCompanies()}
                                </Cell>
                                
                                <Cell className="text-right" small={12} medium={12} large={12} >
                                   {this.buttonUpdate()} 
                                </Cell>
                             
                            </Grid>


                        </form>

                        


                    </Modal>  

            )
        }else{
            return false
        }
    }

    //1 actualización
    //0 sin actualización
    //2 sin cambio

    drawRows(){


        if(this.state.clients.length > 0){
        
            var rows = this.state.clients.map( data => {
                
                var status = ""

                return(
                    <tr>
                    
                        <td>
                            <span>{data.clientName}</span>
                        </td>
                        <td>
                            <span>{data.email}</span>
                        </td>
                        <td>
                            <a style={{marginLeft:"5px"}} href="#" onClick={() => { 
                                this.setState({isEditing:false, title:"Visualizar Cliente"})
                               this.getClient(data.idClient)
                            }}><FontAwesomeIcon icon={faEye} size="1x"/></a>
                            <a style={{marginLeft:"5px"}} href="#" onClick={() => { 
                                this.setState({isEditing:true, title:"Actualizar Cliente"})
                               this.getClient(data.idClient)
                            }}><FontAwesomeIcon icon={faEdit} size="1x"/></a>
                        </td>
                    </tr>
                )
            })

            return rows

        }else{
            return(
                <tr>
                    <td colSpan={4} style={{textAlign:"center"}}>
                        <b>No hay datos que mostrar.</b>
                        <Loader
                            type="Puff"
                            color="#003A66"
                            height={100}
                            width={100}
                            timeout={10000} //3 secs
                        />
                    </td>
                </tr>
            )
        }

    }

    buttonUpdate(){
        
        return this.state.isEditing ? <Button>Actualizar</Button> : false
    }

    render(){
        return(
            <Grid>
                <Cell small={12} medium={12} large={12}>
                    <table className="hover unstriped">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Correo Electrónico</th>
                                {/*<th>Estatus</th>*/}
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.drawRows()}
                        </tbody>
                    </table>

                    {this.drawModal()}
                    
                </Cell>
            </Grid>
        )
    }
}