import React from 'react'

import { Grid, Cell, Button } from 'react-foundation';
import Modal from 'react-foundation-modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import Axios from 'axios'
import config from '../../config/config'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const overlayStyle = {
    'backgroundColor': 'rgba(0,58,102,.45)'
    };

export default class OptionsComponents extends React.Component{
    constructor(){
        super()


        this.state = {
            modalIsOpen: false,
            internalId:"--",
            internal_file_number:"",
            idLawyer:0,
            external_file_number:"",
            rfc:"",
            city:"",
            court:"",
            register_date:"",
            issue:"",
            id_client:0,
            rfcs:[],
            clients:[],
            issues:[]
            
        }

    }



    render(){
        return(
            <Grid className="cn-options">
                <Cell className="cn-search" small={4} medium={4} large={4}>
                    <input placeholder="Buscar" type="text" onChange={(input) => {
                        this.props.onhandleSearch(input.target.value)
                    }}/>
                </Cell>

                <Cell style={{paddingLeft:10}} small={3} medium={3} large={3}>
                    <select name="" id="" onChange={(input) => {
                        this.props.onhandleFilter(input.target.value)
                    }}>
                        <option value="numberExternal">Por número externo</option>
                        <option value="company">Por nombre de empresa</option>
                        <option value="quejosos">Por quejosos</option>
                    </select>
                    
                </Cell>
                <Cell style={{paddingLeft:10}} small={2} medium={2} large={2}>
                    <select name="" id="" placeholder='Elige etapa' onChange={(input)=>{
                        //console.log(input.target.value)
                        this.props.onhandleStageFilter(input.target.value)
                    }}>
                        <option value="0" selected={this.props.stageFilter == 0}>Todas las etapas</option>
                        <option value="1" selected={this.props.stageFilter == 1}>Concluido</option>
                        <option value="2" selected={this.props.stageFilter == 2}>En trámite</option>
                    </select>
                </Cell>
            </Grid>
        )
    }
}