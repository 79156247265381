import React from 'react'
import { Grid, Cell } from 'react-foundation';


export default class IndexHomeComponent extends React.Component{


    render(){
        return(
            <Cell style={{maxHeight:"94vh"}} small={10} medium={10} large={10}>
                <div className="cn-main-components">
                    <h3>Bienvenido, {JSON.parse(sessionStorage.dataUser).name}.</h3>
                </div>
            </Cell>
        )
    }

}