import React from 'react'
import { Grid, Cell, Button } from 'react-foundation';
import PostBinnacleComponent from './postBinnacleComponent'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPlus, faGift} from '@fortawesome/free-solid-svg-icons'

import Modal from 'react-foundation-modal';


const overlayStyle = {
    'backgroundColor': 'rgba(0,58,102,.45)'
    };

export default class BinnacleComponent extends React.Component{

    constructor(){
        super()


        this.state = {
            modalIsOpen: false,
            binnacle:{}
        }

        this.structure = []


        this.drawPosts = this.drawPosts.bind(this)
        this.appearBinnacle = this.appearBinnacle.bind(this)
    }

    appearBinnacle(position){
        const self = this
        self.setState({binnacle:this.props.binnacle[position]},this.showPopup(true))
    }


    drawPosts(){
        if(this.props.binnacle != undefined){
            if(this.props.binnacle.length > 0){
                var aux = 0
                let components =  this.props.binnacle.map(post => {
                    return(
                        <PostBinnacleComponent 
                        onHandleAppear={this.appearBinnacle} 
                        data={post}
                        position={aux++}/>
                    )
                })
                return components
            }else{
                return (
                <div className="text-center">
                    No hay bitácoras registradas para este expediente.
                </div>
                )
            }

        }
    }

    showPopup = (status) => {
        this.setState({
            modalIsOpen: status
        });
    }


    render(){
        return(
            <div>
                <div style={{display:"flex", flexDirection:"row"}}>
                    <div style={{flex:1}}>
                        <h4 style={{marginLeft:"10px"}}>Bitácora</h4>
                    </div>
                    <div style={{flex:1, display:"flex", justifyContent:"flex-end"}}>
                    
                        <Modal 
                        open={this.state.modalIsOpen}
                        closeModal={this.showPopup}
                        isModal={true}
                        size="small"
                        overlayStyle={overlayStyle} >

                        <div style={{textAlign:"left"}}>
                            <h3>Visualizar bitácora</h3>
                            <Grid>

                            <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    Acciones
                                    <input defaultValue={this.state.binnacle.priority == 0 ? "Sin Acción" : "Enviar a socios"} readOnly type="text"/>
                                </Cell>
                           
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Titúlo de bitácora</label>
                                    <input defaultValue={this.state.binnacle.title} readOnly type="text"/>
                                </Cell>
 
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Fecha de audiencia</label>
                                    <input defaultValue={this.state.binnacle.hearing_date} readOnly type="text"/>
                                </Cell>
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Estatus para cliente</label>
                                    <input defaultValue={this.state.binnacle.status} type="text"/>
                                </Cell>
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Observaciones</label>
                                    <textarea defaultValue={this.state.binnacle.observations} name="" id="" cols="30" rows="10"></textarea>
                                </Cell>
                                <Cell className="cn-fr" small={6} medium={6} large={6}>
                                    <label>Notas</label>
                                    <textarea defaultValue={this.state.binnacle.notes} name="" id="" cols="30" rows="10"></textarea>
                                </Cell>

                             
                            </Grid>


                        </div>
                    </Modal>  
                    </div>
                </div>
                
                <div className="cn-binnacle">
                    {this.drawPosts()}
                </div>
           </div>
        )
    }
}