import React from 'react';
import './App.css';
import './styles/main.css';
// import components

import IndexLogInComponent from './components/logIn/indexLogInComponent'
import NavigationRouting from './components/indexRouting/navigationRouting'

export default class App extends React.Component{

  constructor(){
    super()

    this.state = {
      authenticate: false
    }

    this._handleAuth = this._handleAuth.bind(this)
  }

  UNSAFE_componentWillMount(){

    if(sessionStorage.getItem("authenticate")){
      this.setState({authenticate:true})
    }

  }

  _handleAuth(state){
    this.setState({authenticate:state})
  }

  render(){
    if(!this.state.authenticate){
      return (
        <IndexLogInComponent onHandleAuth={this._handleAuth}/>
      );
    }else{
     return(
       <NavigationRouting /> 
     )
    }
    
  }
    
}